/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect, useContext } from 'react';
import Moment from 'react-moment';
import { Tabs } from 'antd';
import LoginContext from '../../context/Login/LoginContext';
import Datatable from '../../Components/Datatable';
import ModalCourses from '../../Components/Modal/ModalCourses';
import { get } from '../../Services/HttpRequest';
import { endPointAllCourses, endPointCoursesActive, endPointCoursesActivecfs } from '../../Services/EndPoints';
import FormPlanning from '../../Components/Forms/FormPlanning';
import ModalAsignPreimpress from '../../Components/Modal/ModalAsignPreimpress';
import { Target } from './Target/Target';

const { TabPane } = Tabs;

const Cursos = () => {
    //Context
    const { rol, user, cfs, state } = useContext(LoginContext);

    //Spin to wait load data.
    const [loading, setLoading] = useState(false);

    //Courses Active
    const [coursesAtive, setCourseAtive] = useState([]);
    const [dataCoursesActive, setDataCoursesActive] = useState([]);
    const [states, setStates] = useState([]); //States
    const [CAActive, setCAActive] = useState([]);

    //CFS Courses Active
    const [statesCFS, setStatesCFS] = useState([]);
    const [dataCoursesActiveCFS, setDataCoursesActiveCFS] = useState([]);
    const [CFSCoursesActiveSelect, setCFSCoursesActiveSelect] = useState([]);

    //Master Courses
    const [generalCoursesMaster, setGeneralCoursesMaster] = useState([]);
    const [CFSCoursesMaster, setCFSCoursesMaster] = useState([]);
    const [infoGeneralMasterCourses, setInfoGeneralMasterCourses] = useState([]);

    //Modals to diferent taps
    const [openModalCFS, setOpenModalCFS] = useState(false);
    const [dataModalCFS, setDataModalCFS] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [dataModal, setDataModal] = useState({});

    const columnsM = [
        { title: 'Formación', width: '30%', dataIndex: 'course', key: 'course' },
        { title: 'Centro', width: '30%', dataIndex: 'cfs', key: 'cfs' },
        {
            title: 'Cupos',
            dataIndex: 'quantity',
            key: 'quantity',
            render: (data) => <p className="text-center">{data}</p>
        },
        {
            title: 'inscritos',
            dataIndex: 'recorded',
            key: 'recorded',
            render: (data) => <p className="text-center">{data}</p>
        },
        {
            title: 'Cursando',
            dataIndex: 'enrolled',
            key: 'enrolled',
            render: (data) => <p className="text-center">{data}</p>
        },
        {
            title: 'Registro',
            dataIndex: 'register',
            key: 'register',
            width: '15%',
            align: 'center',
            render: (data) =>
                <p className="text-center">
                    <Moment format="DD-MM-YYYY">
                        {data.init}
                    </Moment><br />
                    <Moment format="DD-MM-YYYY">
                        {data.end}
                    </Moment>
                </p>
        },
        {
            title: 'Lapso',
            dataIndex: 'lapse',
            key: 'lapse',
            width: '15%',
            align: 'center',
            render: (data) =>
                <p className="text-center">
                    <Moment format="DD-MM-YYYY">
                        {data.init}
                    </Moment><br />
                    <Moment format="DD-MM-YYYY">
                        {data.end}
                    </Moment>
                </p>
        },
    ];

    const columnsMCFS = [
        { title: 'Formación', dataIndex: 'course', key: 'course', width: '25%', },
        {
            title: 'Cupos',
            dataIndex: 'quantity',
            key: 'quantity',
            render: (data) => <p className="text-center">{data}</p>
        },
        {
            title: 'Matriculados',
            dataIndex: 'recorded',
            key: 'recorded',
            align: 'center',
            render: (data) => <p className="text-center">{data}</p>
        },
        {
            title: 'Verificados',
            dataIndex: 'verified',
            key: 'verified',
            align: 'center',
            render: (data) => <p className="text-center">{data}</p>
        },
        {
            title: 'N° de Preimpreso',
            dataIndex: 'preimpress',
            key: 'preimpress',
            width: '15%',
            align: 'center',
            render: (data) => <p className="text-center">{data}</p>
        },
        {
            title: 'Registro',
            dataIndex: 'register',
            key: 'register',
            width: '15%',
            align: 'center',
            render: (data) =>
                <p className="text-center">
                    <Moment format="DD-MM-YYYY">
                        {data.init}
                    </Moment> <br /> <Moment format="DD-MM-YYYY">
                        {data.end}
                    </Moment>
                </p>
        },
        {
            title: 'Lapso',
            dataIndex: 'lapse',
            key: 'lapse',
            width: '15%',
            align: 'center',
            render: (data) =>
                <p className="text-center">
                    <Moment format="DD-MM-YYYY">
                        {data.init}
                    </Moment> <br />
                    <Moment format="DD-MM-YYYY">
                        {data.end}
                    </Moment>
                </p>
        },
        {
            title: 'Acción',
            dataIndex: 'id',
            key: 'id',
            width: '5%',
            render: (data) => {
                return <>
                    {['10', '11', '17'].includes(rol) ? <button
                        className="btn btn-success btn-sm center"
                        onClick={() => showModalMasterCFS(data)}
                        title="Ver"
                    >
                        <i className="far fa-eye"></i>
                    </button> : null}
                </>;
            },
        }
    ];

    const columnsGC = [
        { title: 'Código Maestra', width: '15%', dataIndex: 'shortname', key: 'shortname' },
        { title: 'Formación', dataIndex: 'course', key: 'course' },
        { title: 'Programa', dataIndex: 'category', key: 'category' },
        {
            title: 'Acción',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '10%',
            render: (data) =>
                <span className="btn btn-sm btn-info" style={{ color: '#fff' }} onClick={() => showInfoGeneralCourse(data)} title='Contenidos de Formación'>
                    <i className="fas fa-sm fa-list" />
                </span>,
        }
    ];

    //Load data of active courses that are display in the web app.
    const loadData = async () => {
        let allCoursesActive = [];
        let allStates = states;
        setLoading(true);
        const resCoursesActive = await get(endPointCoursesActive);
        if (resCoursesActive) {
            resCoursesActive.forEach((course, i) => {
                allCoursesActive.push({
                    key: `${i}`,
                    id: course.id_cur_activo,
                    course: course.curso,
                    cfs: course.cfs,
                    quantity: course.cupos,
                    recorded: course.participan,
                    enrolled: course.cursando,
                    register: { init: course.fecha_ini_r, end: course.fecha_fin_r },
                    lapse: { init: course.fecha_ini, end: course.fecha_fin },
                });
                if (!allStates.some(i => i.value === course.estado)) {
                    allStates.push({ value: course.estado });
                }
            });

            setStates([...allStates]);
            setCourseAtive([...allCoursesActive]);
            setDataCoursesActive([...resCoursesActive]);
        }
        setLoading(false);
    };

    //Load all data of the CFS
    const loadDataCFS = async () => {
        let coursesActiveSelect = [];
        let coursesCFS = [];
        let allStates = [];
        setLoading(true);
        const resCFSCourses = await get(endPointCoursesActivecfs, { state, cfs });
        if (resCFSCourses) {
            resCFSCourses.forEach((course, i) => {
                coursesCFS.push({
                    key: `${i}`,
                    id: course.id_cur_activo,
                    course: course.formacion,
                    quantity: course.cupos,
                    recorded: course.registrados,
                    verified: course.verificados,
                    preimpress: course.preimpresos,
                    register: { init: course.fecha_ini_r, end: course.fecha_fin_r },
                    lapse: { init: course.fecha_ini, end: course.fecha_fin },
                });
                if (!allStates.some(i => i.value === course.estado)) {
                    allStates.push({ value: course.estado });
                }
                if (!coursesActiveSelect.some(i => i.value === course.cfs)) {
                    coursesActiveSelect.push({ value: course.cfs });
                }
            });
        }
        setCFSCoursesActiveSelect(coursesActiveSelect);
        setCFSCoursesMaster(coursesCFS);
        setStatesCFS(allStates);
        setDataCoursesActiveCFS(resCFSCourses);
        setLoading(false);
    };

    //Load all data that content the information of the courses
    const loadDataMG = async () => {
        let generalCourses = generalCoursesMaster;
        setLoading(true);
        const resCourses = await get(endPointAllCourses);
        resCourses.forEach(course => {
            generalCourses.push({
                key: `${course.id}`,
                id: course.id,
                shortname: course.shortname,
                course: course.nombre,
                category: course.programa,
            });
        });
        setGeneralCoursesMaster([...generalCourses]);
        setInfoGeneralMasterCourses(resCourses);
        setLoading(false);
    };

    //Handler to change diferent Tabs
    const handleChangeTap = (value) => {
        if (value === '1') {
            //if (coursesAtive.length === 0) {
            loadData();
            //}
        }
        if (value === '2') {
            //if (CFSCoursesMaster.length === 0) {
            loadDataCFS();
            //}
        }
        if (value === '4') {
            if (generalCoursesMaster.length === 0) {
                loadDataMG();
            }
        }
    };

    //Course Master Active complete
    const handleChangeActiveCourseState = (value) => {
        let cfs = [];
        let courses = [];
        const active = value !== '' ? dataCoursesActive.filter((course) => course.estado === value) : dataCoursesActive;
        active.forEach(((e, i) => {
            courses.push({
                key: `${i}`,
                id: e.id_cur_activo,
                course: e.curso,
                cfs: e.cfs,
                quantity: e.cupos,
                recorded: e.participan,
                enrolled: e.cursando,
                register: { init: e.fecha_ini_r, end: e.fecha_fin_r },
                lapse: { init: e.fecha_ini, end: e.fecha_fin },
            });
            if (!cfs.some(i => i.value === e.cfs) && value !== '') {
                cfs.push({ value: e.cfs });
            }
        }));
        setCourseAtive(courses);
        setCAActive(cfs);
    };

    const handleChangeCFSCourseActive = (value) => {
        let courses = [];
        const CAActive = dataCoursesActive.filter((course) => course.cfs === value);
        CAActive.forEach(((e, i) => {
            courses.push({
                key: `${i}`,
                id: e.id_cur_activo,
                course: e.curso,
                cfs: e.cfs,
                quantity: e.cupos,
                recorded: e.participan,
                enrolled: e.cursando,
                register: { init: e.fecha_ini_r, end: e.fecha_fin_r },
                lapse: { init: e.fecha_ini, end: e.fecha_fin },
            });
        }));
        setCourseAtive(courses);
    };

    //CFS courses master
    const showModalMasterCFS = (value) => {
        let data = dataCoursesActiveCFS.filter((element) => element.id_cur_activo === value)[0];
        setOpenModalCFS(!openModalCFS);
        setDataModalCFS(data);
    };

    const closeModalMasterCFS = () => {
        setOpenModalCFS(!openModalCFS);
        loadDataCFS();
    };

    const handleChangeCourseActiveCFSState = (value) => {
        let cfs = [];
        let courses = [];
        const active = value !== '' ? dataCoursesActiveCFS.filter((course) => course.estado === value) : dataCoursesActiveCFS;
        active.forEach(((e, i) => {
            courses.push({
                key: `${i}`,
                id: e.id_cur_activo,
                course: e.formacion,
                quantity: e.cupos,
                recorded: e.registrados,
                verified: e.verificados,
                preimpress: e.preimpresos,
                register: { init: e.fecha_ini_r, end: e.fecha_fin_r },
                lapse: { init: e.fecha_ini, end: e.fecha_fin },
            });
            if (!cfs.some(i => i.value === e.cfs) && value !== '') {
                cfs.push({ value: e.cfs });
            }
        }));
        setCFSCoursesMaster(courses);
        setCFSCoursesActiveSelect(cfs);
    };

    const handleChangeCourseActiveCFS = (value) => {
        let courses = [];
        const coursesActive = value !== '' ? dataCoursesActiveCFS.filter((course) => course.cfs === value) : dataCoursesActiveCFS;
        coursesActive.forEach(((e, i) => {
            courses.push({
                key: `${i}`,
                id: e.id_cur_activo,
                course: e.formacion,
                quantity: e.cupos,
                recorded: e.registrados,
                verified: e.verificados,
                preimpress: e.preimpresos,
                register: { init: e.fecha_ini_r, end: e.fecha_fin_r },
                lapse: { init: e.fecha_ini, end: e.fecha_fin },
            });
        }));

        setCFSCoursesMaster(courses);
    };

    //General Master
    const showInfoGeneralCourse = (value) => {
        let data = infoGeneralMasterCourses.filter((element) => element.id === value)[0];
        console.log(data);
        setDataModal(data);
        setOpenModal(!openModal);
    };

    //Action from planning form
    const reloadByPlanning = () => {
        if (['10', '11'].includes(rol)) {
            loadData();
        }
        if (['10', '11', '16', '17'].includes(rol)) {
            loadDataCFS();
        }
    };

    useEffect(() => {
        loadDataMG();

        /* if (['10', '11'].includes(rol)) {
            loadData();
        }
        if (['16', '17'].includes(rol)) {
            loadDataCFS();
        } */
    }, []);

    return (
        <Fragment>
            <div className="row page-titles mt-3">
                <div className="col-md-10">
                    <h6 className="breadcrumb-item m-b-0">Administración de Formaciones</h6>
                </div>
            </div>
            <div className="row mt-3">
                <div className="card">
                    <div className="card-block mt-2">
                        <div className="tab-content">
                            <Tabs defaultActiveKey="1" type="card" onChange={handleChangeTap}>
                                {/*['10', '11'].includes(rol) ?
                                    <TabPane tab={`Formación Activa Web`} key="1" className="tab-pane p-20">
                                        <div className="row mb-1">
                                            <div className="col-md-2 ">
                                                <select
                                                    className="form-select"
                                                    onChange={e => handleChangeActiveCourseState(e.target.value)}
                                                >
                                                    <option value=""> - Estado -</option>
                                                    {states.map((state, i) =>
                                                        <option key={i} value={state.value}> {state.value} </option>
                                                    )}
                                                </select>
                                            </div>
                                            <div className="col-md-2 ">
                                                <select
                                                    className="form-select"
                                                    onChange={(e) => handleChangeCFSCourseActive(e.target.value)}
                                                >
                                                    <option value="" > - Ámbitos -</option>
                                                    {CAActive.map((active, i) =>
                                                        <option key={i} value={active.value}> {active.value} </option>
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                        <Datatable bordered columns={columnsM} data={coursesAtive} />
                                    </TabPane> : null
                                */ }
                                {/*<TabPane tab="Formación de Ambitos" key="2" className="tab-pane p-20">
                                    <div className="row mb-1">
                                        {['10', '11'].includes(rol) ? <div className="col-md-2 ">
                                            <select
                                                className="form-select"
                                                onChange={(e) => handleChangeCourseActiveCFSState(e.target.value)}
                                            >
                                                <option value=""> - Estado -</option>
                                                {statesCFS.map((state, i) =>
                                                    <option key={i} value={state.value}> {state.value} </option>
                                                )}
                                            </select>
                                        </div> : null}
                                        {['10', '11', '17'].includes(rol) ? <div className="col-md-2 ">
                                            <select name="" id=""
                                                className="form-select"
                                                onChange={(e) => handleChangeCourseActiveCFS(e.target.value)}
                                            >
                                                <option value=""> - Ambito -</option>
                                                {CFSCoursesActiveSelect.map((active, i) =>
                                                    <option key={i} value={active.value}> {active.value} </option>
                                                )}
                                            </select>
                                        </div> : null}
                                    </div>
                                    <Datatable bordered columns={columnsMCFS} data={CFSCoursesMaster} />
                                    <ModalAsignPreimpress
                                        show={openModalCFS}
                                        data={dataModalCFS}
                                        onHide={() => setOpenModalCFS(!openModalCFS)}
                                        action={() => closeModalMasterCFS()}
                                    />;
                                </TabPane>*/}
                                {/* ['10', '11', '16', '17'].includes(rol) ? <TabPane tab="Planificación de Formación" key="3" className="tab-pane p-20">
                                    <FormPlanning reloadDT={reloadByPlanning} />
                                </TabPane> : null */}
                                <TabPane tab="Maestra - General" key="4" className="tab-pane p-20">
                                    <Datatable bordered columns={columnsGC} data={generalCoursesMaster} size={'small'} />
                                    {openModal && <ModalCourses
                                        show={openModal}
                                        data={dataModal}
                                        onHide={() => setOpenModal(!openModal)}
                                        action={() => { }}
                                    />}
                                </TabPane>
                                {/* ['10', '11', '17'].includes(rol) ? <TabPane tab="Metas - Ámbitos" key="5" className="tab-pane p-20"><Target /></TabPane> : null */}
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    );
};

export default Cursos;
