/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect, useContext } from 'react';
import LoginContext from '../../context/Login/LoginContext';
import { Tabs } from 'antd';
import { Areas } from './Core/Areas/Areas';
import { FormAreas } from './Core/Areas/FormAreas';
import { data } from 'jquery';
import { Cores } from './Core/Cores/Cores';
import { FormCores } from './Core/Cores/FormCores';

const { TabPane } = Tabs;

const defaultAreas = { id: '', name: '', description: '' };
const defaultCore = { id: '', code: '', name: '', address: '', state: '', scope: '', township: '', parish: '' };

const Centros = () => {

    //Context
    const { rol } = useContext(LoginContext);

    //Tab - Nucleos
    const [cores, setCores] = useState(true);
    const [dataCores, setDataCores] = useState(defaultCore);
    //Tab - Areas
    const [areas, setAreas] = useState(true);
    const [dataAreas, setDataAreas] = useState(defaultAreas);

    //Function to tab - Nucleos
    const addCores = () => {
        setDataCores(defaultCore);
        setCores(false);
    };

    const updateCores = (data) => {
        setDataCores(data);
        setCores(false);
    };

    //Function to tab - Areas
    const addAreas = () => {
        setDataAreas(defaultAreas);
        setAreas(false);
    };

    const updateAreas = (data) => {
        setDataAreas(data);
        setAreas(false);
    };

    return (
        <Fragment>
            <div className="row page-titles mt-3">
                <div className="col-md-10">
                    <h6 className="breadcrumb-item m-b-0">Administración de las Organizaciones</h6>
                </div>
            </div>
            <div className="row mt-3">
                <div className="card">
                    <div className="card-block mt-2">
                        <div className="tab-content">
                            <Tabs defaultActiveKey="1" type="card">
                                <TabPane tab="Organizaciones" key="1" className="tab-pane p-20">
                                    {cores ?
                                        <Cores add={addCores} action={updateCores} />
                                        : <FormCores action={setCores} data={dataCores} />}
                                </TabPane>
                                {['10', '11'].includes(rol) ? <TabPane tab="Ámbitos" key="2" className="tab-pane p-20">
                                    {areas ?
                                        <>
                                            <div className="col-1 ms-auto mb-1">
                                                <button className="btn btn-outline-success" onClick={() => addAreas()}> Agregar <i className="fas fa-layer-group"></i></button>
                                            </div>
                                            <Areas action={updateAreas} />
                                        </>
                                        : <FormAreas action={setAreas} data={dataAreas} />}
                                </TabPane> : null}
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Centros;

