
import Login from "../Pages/Auth/Login";
import Matriculado from "../Pages/Admin/Matriculado";
import Dashboard from "../Pages/Admin/Dashboard";
import Centros from "../Pages/Admin/Centros";
import Usuarios from "../Pages/Admin/Usuarios";
import Cursos from "../Pages/Admin/Cursos";
import Landingpage from "../Pages/Public/Landingpage";
import CertificateVerification from "../Pages/Public/CertificateVerification";
import Certificado from "../Pages/Admin/Certificado";

const routes = [
    {
        path: '/home',
        exact: true,
        private: true,
        name: 'Dashboard',
        component: () => <Dashboard />,
    },
    {
        path: '/matriculados',
        exact: true,
        private: true,
        name: 'Matriculados',
        component: () => <Matriculado />,
    },
    {
        path: '/centros',
        exact: true,
        private: true,
        name: 'Organizaciones',
        component: () => <Centros />,
    },
    {
        path: '/formacion',
        exact: true,
        private: true,
        name: 'Formación',
        component: () => <Cursos />,
    },
    {
        path: '/usuarios',
        exact: true,
        private: true,
        name: 'Usuarios',
        component: () => <Usuarios />,
    },
    {
        path: '/certificado',
        exact: true,
        private: true,
        name: 'Certificado',
        component: () => <Certificado />,
    },
    {
        path: '/admin',
        exact: true,
        component: () => <Login />,
    },
    {
        path: '/verifycertificate',
        exact: true,
        component: () => <CertificateVerification />,
    },
    {
        path: '/',
        exact: true,
        component: () => <Landingpage />,
    },
];

export default routes;