/**
 * Headers Library
 */
import axios from 'axios';
/**
 * TODO: Use parameterized configurations.
 */

let config = {
    headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem('authorization')}`,
    },
};

export const get = async (endpoint = '', payload = '') => {
    try {
        let req;
        if (payload) {
            config['params'] = payload;
            req = await axios.get(endpoint, config);
        } else {
            req = await axios.get(endpoint, config);
        }
        delete config.params;
        return req.data;
    } catch (error) {
        console.log('An error occurred => ' + error);
        return error;
    }
};

export const post = async (endpoint = '', payload = '', multipart = false) => {
    try {
        let req;
        if (multipart) {
            config['headers']['Content-Type'] = 'multipart/form-data';
            req = await axios.post(endpoint, payload, config);
        } else {
            req = await axios.post(endpoint, payload, config);
        }
        return req.data;
    } catch (error) {
        throw(error.response);
    }
};

export const put = async (endpoint = '', payload = '') => {
    try {
        let req = await axios.post(endpoint, payload, config);
        return req.data;
    } catch (error) {
        console.log('An error occurred => ' + error);
    }
};

/**
 * TODO: The patch and delete functions are still pending
 */