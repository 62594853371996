import Config from "./Config";

//Routers to Auth and Access to admiin app.
export const endPointUserTokenAuth = `${Config.API_URL}validateuser`;
export const endPointVerifyUsername = `${Config.API_URL}verifyusername`;
export const endPointVerifyTempPass = `${Config.API_URL}verifytemppassword`;
export const endPointUserChangePass = `${Config.API_URL}userchangepassword`;
export const endPointUserChangeAccess = `${Config.API_URL}userchangeaccess`;
export const endPointResetUserPass = `${Config.API_URL}resetuserpass`;
export const endPointVerifyToken = `${Config.API_URL}verifytoken`;

// Routers to Dashboard
export const endPointDashboardCounters = `${Config.API_URL}dashboardcounters`;

//Routers to cores & areas
export const endPointAllAreas = `${Config.API_URL}areas`;
export const endPointAreaShortname = `${Config.API_URL}areashortname`;
export const endPointCreateArea = `${Config.API_URL}createarea`;
export const endPointUpdateArea = `${Config.API_URL}updatearea`;
export const endPointCores = `${Config.API_URL}cores`;
export const endPointCreateCore = `${Config.API_URL}createcore`;
export const endPointCreateMasiveCore = `${Config.API_URL}createmasivecore`;
export const endPointUpdateCore = `${Config.API_URL}updatecore`;

// Router to persons
export const endPointCheckEmailPerson = `${Config.API_URL}checkemailperson`;
export const endPointGetPerson = `${Config.API_URL}getperson`;
export const endPointCreatePerson = `${Config.API_URL}createperson`;
export const endPointUpdatePerson = `${Config.API_URL}updateperson`;

// Routers to users
export const endPointGetUser = `${Config.API_URL}getuser`;
export const endPointCreateUser = `${Config.API_URL}createuser`;
export const endPointGetUsers = `${Config.API_URL}getusers`;

//Routers to Certificates
export const endPointGetCertificates = `${Config.API_URL}certificates`;
export const endPointVerifyCertificate = `${Config.API_URL}verifycertificate`;
export const endPointMasiveCertificate = `${Config.API_URL}masivecertificate`;

// Routers to courses
export const endPointAllCourses = `${Config.API_URL}allcourses`;
export const endPointOfficeCourses = `${Config.API_URL}officecourses`;
export const endPointCoursesActive = `${Config.API_URL}coursesactive`;
export const endPointCoursesActivecfs = `${Config.API_URL}coursesactivecfs`;
export const endPointCreateCourseActive = `${Config.API_URL}createcourseactive`;
export const endPointMasterCourseCode = `${Config.API_URL}mastercoursecode`;
export const endPointCoursesParticipant = `${Config.API_URL}coursesparticipant`;
export const endPointStatusParticipant = `${Config.API_URL}participantstatus`;
export const endPointCourseContent = `${Config.API_URL}coursecontent`;

//Routers to enrolled
export const endPointGroupParticipant = `${Config.API_URL}groupparticipant`;
export const endPointListParticipant = `${Config.API_URL}listparticipant`;
export const endPointPaticipantEnrolled = `${Config.API_URL}participantsenrolled`;
export const endPointVerifyPaticipant = `${Config.API_URL}verifyparticipant`;
export const endPointGroupPaticipantEnroll = `${Config.API_URL}groupparticipantenroll`;

//Routers to preimpress
export const endPointGetPreimpressCourse = `${Config.API_URL}preimpressbycourse`;
export const endPointSetPreimpress = `${Config.API_URL}createpreimpress`;

//Routers to places
export const endPointStates = `${Config.API_URL}states`;
export const endPointCities = `${Config.API_URL}cities`;
export const endPointTownships = `${Config.API_URL}townships`;
export const endPointParishes = `${Config.API_URL}parishes`;
export const endPointOfficesStates = `${Config.API_URL}officesstates`;

//Routers to targets
export const endPointGeneralTargets = `${Config.API_URL}generaltargets`;
export const endPointTargetsByPeriod = `${Config.API_URL}targetsbyperiod`;
export const endPointStateTargets = `${Config.API_URL}statetargets`;
export const endPointCreateTarget = `${Config.API_URL}createtarget`;
export const endPointUpdateTarget = `${Config.API_URL}updatetarget`;
