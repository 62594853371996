import React, { Fragment, useContext } from 'react';

import LayoutContext from './context/layout/LayoutContext';

import Content from './Components/Layout/Content';
import Sidebar from './Components/Layout/Sidebar';
import Navbar from './Components/Layout/Navbar';

function App() {
    const { sidebar, navbar, miniSidebar, content } = useContext(LayoutContext);
    return (
        <Fragment>
            <div className={`${content ?
                `fix-header fix-sidebar ${miniSidebar ? 'mini-sidebar' : ''}` :
                ''}`
            }>
                <div id={`${content ? "main-wrapper" : ''}`}>
                    {navbar && <Navbar />}
                    {sidebar && <Sidebar />}
                    <div className={`${content ? "page-wrapper" : ''}`}>
                        <Content />
                    </div>
                </div>
            </div>
        </Fragment>

    );
}

export default App;
