export const infoSaberes = {
    title: 'Acreditación y Certificación de Saberes',
    desciption: 'El Inces certifica los saberes y conocimientos de quienes a lo largo de su vida aprendieron un oficio. Son muchos los hombres y mujeres que tienen la habilidad y destreza para ejercer tareas técnicas sin haber pasado por la academia. Con rigurosidad y bajo una metodología estricta, certificamos a quienes demuestran manejo del área del conocimiento en un área específica.'
};

export const infoTrabajo = {
    title: 'Formación en Entidades de Trabajo',
    desciption: 'Vamos hasta las empresas a detectar cuáles son las necesidades formativas reales directamente en el campo de trabajo. Además, caracterizamos a los trabajadores que requieren formación y articulamos un plan específico para cada entidad laboral, reconociendo las líneas de desarrollo estratégico, la capacidad instalada y la demanda formativa de cada empresa.'
};

export const infoMilitar = {
    title: 'Programa Inces Militar',
    desciption: 'Gracias al convenio que establecimos con el Ministerio del Poder Popular para la Defensa, contamos con un programa de formación dirigido al personal militar y civil de la Fuerza Armada Nacional Bolivariana, brindando a los futuros reservistas y personal civil, la oportunidad de incorporarse al aparato productivo nacional.'
};

export const infoBachillerato = {
    title: 'Programa Bachillerato Productivo',
    desciption: 'Ofrecemos a quienes no culminaron sus estudios formales la oportunidad de obtener el titulo de bachiller; con el valor agregado de aprender una ocupación productiva. Además, quien cursa estudios formales en el Inces, tiene la opción de homologar sus conocimientos y cursar sólo aquellas materias de las cuales no tiene conocimiento. En un máximo de tres años eres un bachiller productivo de la República'
};

export const infoAprendices = {
    title: 'Programa Nacional de Aprendizaje',
    desciption: 'Es el plan diseñado para los jóvenes que tienen entre 14 y 18 años, con deseos de aprender una ocupación productiva e ingresar al mundo laboral. Es la materialización de la premisa “aprender haciendo”. El joven es acogido en las entidades de trabajo y obtiene todos los beneficios que ofrece la ley, siempre bajo tutela pedagógica del Inces.'
};

export const infoProductivo = {
    title: 'Programa de Formación Productiva ',
    desciption: 'Es el programa que garantiza a los y las jóvenes incluirse en los planes nacionales de formación y proyectos de formación y autoformación productiva desarrolladas por el Inces en las áreas de conocimiento agroalimentario, industrial, turismo, salud, ferroviario, hidrocarburos, petroquímica, telecomunicaciones, economía comunal, entre otras; vinculadas a los planes de desarrollo estratégico delineados por el Ejecutivo Nacional'
};