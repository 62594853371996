/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react';
import { Table } from 'antd';

const Datatable = ({ columns, data, size, title = '', loading = false, ...props }) => {
    const [dataSource, setData] = useState([...data]);
    const [q, setQ] = useState("");

    const search = (rows) => {
        const columns = rows[0] && Object.keys(rows[0]);
        //return rows.filter(row => columns.some(column => row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1));

        return rows.filter(row => columns.some(column => row[column] && row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1));
    };

    useEffect(() => {
        setData(data);
    }, [data]);

    return (
        <Fragment>
            <div className="row">
                <div className="card">
                    {title !== '' ? <div class="card-header">
                        {title}
                    </div> : null}
                    <div className="card-body">
                        <div className="col-md-2 ms-auto">
                            <div className="input-group flex-nowrap ">
                                <input type="text" className="form-control" placeholder="Buscar" aria-describedby="addon-wrapping"
                                    value={q}
                                    onChange={(e) => {
                                        setQ(e.target.value);
                                    }} />
                                <span className="input-group-text" id="addon-wrapping"><i className="fas fa-search"></i></span>
                            </div>
                        </div>
                        <div className="mt-2">
                            <Table {...props} columns={columns} dataSource={search(dataSource)} size={size} loading={loading} />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Datatable;
