/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { post } from "../../Services/HttpRequest";
import logo from "../../Assets/images/logo.svg";
import { endPointVerifyCertificate } from "../../Services/EndPoints";

const styleCintillo = {
    width: "100%",
    position: "sticky",
    bottom: 0,
};

const styleFooter = {
    bottom: "0",
    position: "sticky",
    top: "100%",
};
const CertificateVerification = () => {
    const location = useLocation();
    const [certificate, setCertificate] = useState([]);
    const [contents, setContents] = useState([]);

    const loadData = async () => {
        const param = location.search.split("=")[1];
        const response = await post(endPointVerifyCertificate, {
            parameter: param,
        });
        setCertificate(response);
        setContents(response.contents);
    };

    const textVerifications = () => {
        let {fullname, cedula, tipo_formacion, shortname, formacion, fecha_ini, fecha_fin, duracion, año, titulo_asociado, fecha_emision, state} = certificate;

        let message = '';

        switch (tipo_formacion) {
            case "CURSO":
                tipo_formacion = ` el siguiente <strong>${tipo_formacion}</strong>`;
                break;
            case "CERTIFICACIÓN, ACREDITACIÓN SABERES EMPÍRICOS Y ACADÉMICOS":
                tipo_formacion = ` la siguiente <strong>${tipo_formacion}</strong>`;
                break;
            case "CERTIFICACIÓN OCUPACIONAL":
                tipo_formacion = ` la siguiente <strong>${tipo_formacion}</strong>`;
                break;
            case "UNIDAD CURRICULAR":
                tipo_formacion = ` la siguiente <strong>${tipo_formacion}</strong>`;
                break;
            case "PERFIL PRODUCTIVO":
                let [day, month, year] = fecha_emision.split("/");let date_emition = new Date(year, month - 1, day);
                let compare_date = new Date('2024-01-01');
                tipo_formacion = `la formación del <strong>${tipo_formacion}</strong>`;
                if(date_emition >= compare_date){
                    tipo_formacion += ' LABORAL';
                }
                break;
            case "PERFIL PRODUCTIVO LABORAL":
                tipo_formacion = ` el siguiente <strong>${tipo_formacion}</strong>`;
                break;
            default:
                break;
        }
        message = `Se hace constar que el participante, <strong>${fullname}</strong>, cédula <strong> ${cedula}</strong>, ha aprobado ${tipo_formacion}, <strong>${formacion}</strong>, código <strong>${shortname}</strong>, en nuestros centros de formación del Inces <strong>${state}</strong>, desde <strong>${fecha_ini} </strong> hasta <strong>${fecha_fin}</strong>${duracion > 0 ? `, cumpliendo con la cantidad de <strong>${duracion}</strong> horas` : ""}. ${tipo_formacion === "PERFIL PRODUCTIVO"? PerfilProductivo(año,titulo_asociado): ""}`;

        return <div dangerouslySetInnerHTML={{ __html: message }} />;
    };

    const PerfilProductivo = (year, titulo) => {
        return `Para el año escolar <strong> ${year} </strong>. Vinculado al componente laboral de la Educación Media General en la Modalidad de Jóvenes, Adultas y Adultos, código de título de bachiller: <strong> ${titulo} </strong>`;
    };
    useEffect(() => {
        loadData();
    }, []);

    console.log(certificate.contents);
    return (
        <Fragment>
            <div className="fondo"></div>
            <div className="container mt-5">
                <div className="row">
                    <div className="logo-verify">
                        <div className="col-6">
                            <img className="img-fluid" src={logo} alt="" />
                        </div>
                    </div>
                </div>
                <div className="row verification">
                    <div className="col-md-10 offset-md-1">
                        <div className="card border-0 card-no-bg">
                            <div className="card-header border-0 card-no-bg">
                                <h3
                                    className="card-title text-center"
                                    style={{ color: "red" }}
                                >
                                    Constancia de verificación INCES
                                </h3>
                            </div>
                            <div className="card-body border-0">
                                <div className="row d-flex justify-content-center">
                                    <div className="col-12">
                                        <p className="card-text mt-2 text-justify">
                                            {textVerifications()}
                                        </p>
                                        <p className="card-text mt-3 text-justify">
                                            Dicho registro puede ser vericado
                                            por el siguiente codigo de ejecución{" "}
                                            <strong>
                                                {" "}
                                                {certificate.preimpreso}{" "}
                                            </strong>
                                            . Fecha de emisión{" "}
                                            <strong>
                                                {certificate.fecha_emision}
                                            </strong>
                                            .
                                        </p>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-between">
                                    <div className="col-12">
                                        <p className="card-title mt-3" style={{ color: "red" }}>PLAN DE ESTUDIO:</p>
                                        <ol className="list-group">
                                        {contents.map((item, index) => {
                                            let subject = item.contenido;
                                            if(item.contenido && item.contenido.includes("_")){
                                                const [code, sbjt] = item.contenido.split("_")
                                                subject = `${code} - ${sbjt}`;
                                            }

                                            return  <>
                                                <li className="list-group-item m-0 d-flex justify-content-between card-no-bg">{index + 1}.- {subject} <small className="ms-3 text-muted">{item.horas} Horas</small></li>
                                                </>
                                        })}
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer border-0">
                                <p className="text-center">
                                    Distrito Capital -{" "}
                                    {moment().format("DD/MM/YYYY")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={styleCintillo}>
                <div id="cintillo">
                    <div className="row d-flex justify-content-between">
                        <div className="col-10 ms-auto"></div>
                        <div className="col-2 showBatalla"></div>
                    </div>
                </div>
            </div>
            <footer style={styleFooter}>
                <hr />
                <p className="text-center p-4">
                    Hecho para el Sitio Web INCES Copyright ©{" "}
                    {moment().format("YYYY")} Rif: G-20009922-4
                </p>
            </footer>
        </Fragment>
    );
};

export default CertificateVerification;
