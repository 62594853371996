/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import { get, post } from "../../Services/HttpRequest";
import { AlertOk } from "../Alert/Alert";
import {
    endPointCheckEmailPerson,
    endPointGetPerson,
    endPointCreatePerson,
    endPointUpdatePerson
} from "../../Services/EndPoints";

const Cedula = ({ field, form, action, ...props }) => {
    const formatChars = {
        a: "[VEPvep]",
        9: "[0-9]",
        "*": "[A-Za-z0-9]",
    };

    return (
        <InputMask
            {...props}
            mask="a-99999999999"
            maskChar=" "
            formatChars={formatChars}
            style={{ textTransform: "uppercase" }}
            name={field.name}
            onChange={(e) => {
                let value = e.target.value.trim();
                if (value !== "-" && value !== "") {
                    form.setFieldValue(field.name, value.toUpperCase());
                } else {
                    form.setFieldError(field.name, "Requeridas");
                }
            }}
        />
    );
};
const FormParticipant = () => {

    const [dataForm, setDataForm] = useState({
        cardId: "",
        names: "",
        surnames: "",
        email: "",
        gender: "",
        birthday : "",
        phone: "",
    });
    const [isUpdate, setIsUpdate] = useState(false);

    const Schema = Yup.object().shape({
        cardId: Yup.string().required("Requerida"),
        names: Yup.string().required("Requerido"),
        surnames: Yup.string().required("Requerido"),
        email: Yup.string()
            .email("Debe ser un Correo Valido")
            .required("Requerido"),
        gender: Yup.string().required("Requerido"),
        birthday : Yup.string().required("Requerido"),
        phone: Yup.string().required("Requerido"),
    });

    const findPerson = async (value) => {
        setDataForm({
            cardId: "",
            names: "",
            surnames: "",
            email: "",
            gender: "",
            birthday : "",
            phone: "",
        });

        if (value.target.value !== "") {
            let nac = value.target.value.split("-")[0];

            let id_person = value.target.value.split("-")[1].trim();

            const regEx = ["V", "v"].includes(nac)
                ? new RegExp(`^[V]-[0-9]{5,8}$`, "gi")
                : new RegExp(`^[P|E]-[0-9]{5,11}$`, "gi");

            if (regEx.test(value.target.value.trim())) {
                const response = await post(endPointGetPerson, {
                    id_person: id_person,
                    nac: nac.toUpperCase(),
                });

                if (response) {
                    setDataForm({
                        cardId: value.target.value.toUpperCase().trim(),
                        names: response.nombres,
                        surnames: response.apellidos,
                        email: response.correo,
                        gender: response.sexo,
                        birthday : response.fecha_nace,
                        phone: response.telefono,
                    });
                    setIsUpdate(true);
                } else {
                    setIsUpdate(false);
                    AlertOk(
                        "Información!",
                        "orange",
                        `La Cédula ${value.target.value
                            .toUpperCase()
                            .trim()} no esta asociado a un participante, llene los campos para registrarlo!`,
                        { ok: "Ok" },
                        () => {}
                    );
                }
            } else {
                AlertOk(
                    "Advertencia!",
                    "orange",
                    `El documento de identificación ${value.target.value.trim()} no cumple con lo requerido!`,
                    { ok: "Ok" }
                );
            }
        }
    };

    const checkEmail = async (email, form) => {
        let cardId = form.values.cardId;
        console.log(cardId);
        if (email !== "" && cardId !== "") {
            const res = await get(endPointCheckEmailPerson, { email });
            if (res) {
                AlertOk(
                    "Atencion!",
                    "orange",
                    `<span>"${email}"</span> <br /> Correo Electrónico se encuentra registrado en la base de datos. Es importante destacar que cada correo debe ser para un solo participante.`,
                    { ok: "Ok" }
                );
                form.setTouched({ email: true }, false);
            }
            return res;
        }
    };

    const cleanForm = () => {

        setDataForm({
            cardId: "",
            names: "",
            surnames: "",
            email: "",
            gender: "",
            birthday : "",
            phone: "",
        });
        setIsUpdate(false);
    };

    return (
        <Fragment>
            <div className="card card-block p-2 ">
                <h6 className="box-title ">Datos del Prticipantes</h6>
                <Formik
                    initialValues={dataForm}
                    validationSchema={Schema}
                    onSubmit={async (values, { resetForm }) => {
                        try{
                            let action = "creado";
                            let response;
                            if(isUpdate){
                                response = await post(endPointUpdatePerson, values)
                                action = "actualizado"
                            }else{
                                response = await post(endPointCreatePerson, values)
                            }

                            AlertOk(
                                'Información!',
                                'green',
                                `Muy bien! <br /> <spam className='mt-2'>El usuario ha sido ${action} de forma satisfactoria</spam> `,
                                { ok: 'Ok' }
                            );
                            setIsUpdate(false);
                            resetForm({ values: Object.fromEntries(Object.keys(values).map(key => [key, ''])) });
                        }catch(e){
                            console.log(e.message)
                            AlertOk(
                                `Error ${e.status}!`,
                                'red',
                                `Ah ocurrido algo inesperado! <br /> <spam className='mt-2'>Hubo problemas con el servidor: \n ${e.data.message}.</spam> `,
                                { ok: 'Ok' }
                            );
                        }
                    }}
                    enableReinitialize
                >
                    {({
                        handleSubmit,
                        resetForm,
                        isSubmitting,
                        errors,
                        touched,
                        values,
                    }) => {
                        return (
                            <Form className="mt-1" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-3">
                                        <label htmlFor="cardId">Cédula</label>
                                        <Field
                                            component={Cedula}
                                            onBlur={findPerson}
                                            className="form-control"
                                            name="cardId"
                                            placeholder="V-12345678"
                                        />
                                        {errors.cardId && touched.cardId ? (
                                            <div className="input-feedback">
                                                {errors.cardId}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-6">
                                        <label htmlFor="names">Nombres</label>
                                        <Field
                                            className="form-control"
                                            name="names"
                                            placeholder="Jane"
                                        />
                                        {errors.names && touched.names ? (
                                            <div className="input-feedback">
                                                {errors.names}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="surnames">
                                            Apellidos
                                        </label>
                                        <Field
                                            className="form-control"
                                            name="surnames"
                                            placeholder="Doe"
                                        />
                                        {errors.surnames && touched.surnames ? (
                                            <div className="input-feedback">
                                                {errors.surnames}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-6">
                                        <label htmlFor="surnames">
                                            Fecha de Nacimiento
                                        </label>
                                        <Field
                                            type="date"
                                            className="form-control"
                                            name="birthday"
                                        />
                                        {errors.birthday  &&
                                        touched.birthday  ? (
                                            <div className="input-feedback">
                                                {errors.birthday }
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="gender">Genero</label>
                                        <Field name="gender">
                                            {({ field, props, form, meta }) => (
                                                <select
                                                    {...field}
                                                    className="form-select"
                                                >
                                                    <option value="">
                                                        -- Seleccione --
                                                    </option>
                                                    <option value="M">
                                                        Masculino
                                                    </option>
                                                    <option value="F">
                                                        Femenino
                                                    </option>
                                                </select>
                                            )}
                                        </Field>
                                        {errors.gender && touched.gender ? (
                                            <div className="input-feedback">
                                                {errors.gender}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-6">
                                        <label htmlFor="lastName">
                                            Teléfono
                                        </label>
                                        <Field name="phone">
                                            {({ field, props, form, meta }) => {
                                                return (
                                                    <InputMask
                                                        {...field}
                                                        className="form-control"
                                                        placeholder="0412 234 56789"
                                                        mask="9999 999 9999"
                                                        maskChar=" "
                                                    />
                                                );
                                            }}
                                        </Field>
                                        {errors.phone && touched.phone ? (
                                            <div className="input-feedback">
                                                {errors.phone}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="email">Correo</label>
                                        <Field name="email">
                                            {({ field, props, form, meta }) => (
                                                <input
                                                    {...props}
                                                    {...field}
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="jane@acme.com"
                                                    onBlur={(e) =>
                                                        checkEmail(
                                                            e.target.value,
                                                            form
                                                        )
                                                    }
                                                />
                                            )}
                                        </Field>
                                        {errors.email && touched.email ? (
                                            <div className="input-feedback">
                                                {errors.email}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="row mt-5 mb-2 ">
                                    <div className="col-md-4 ml-5 ms-auto">
                                        <button
                                            className="btn btn-inverse m-r-10"
                                            onClick={() => {cleanForm();} }
                                        >Cancelar<i className="fas fa-undo"></i>
                                        </button>
                                        <button
                                            className="btn btn-success"
                                            type="submit"
                                            disabled={isSubmitting}
                                        >
                                            {" "}
                                            {isUpdate
                                                ? "Actualizar"
                                                : "Registrar"}{" "}
                                            <i className="far fa-paper-plane"></i>
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </Fragment>
    );
};

export default FormParticipant;
