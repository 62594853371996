/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect, useContext } from 'react';
import Moment from 'react-moment';
import { Tabs } from 'antd';
import LoginContext from '../../context/Login/LoginContext';
import { AlertOk } from '../../Components/Alert/Alert';
import { post } from '../../Services/HttpRequest';
import { endPointMasiveCertificate } from '../../Services/EndPoints';
import moment from 'moment';

const { TabPane } = Tabs;

const arrayHeaders = [
    "CONSECUTIVO",
    "ESTADO",
    "CFS",
    "PREIMPRESO",
    "MAESTRA",
    "TIPO DE FORMACIÓN",
    "ÁMBITO",
    "INICIO",
    "CIERRE",
    "NOMBRES",
    "APELLIDOS",
    "NACIONALIDAD",
    "CEDULA",
    "TELÉFONO",
    "CORREO",
    "NACIMIENTO",
    "GENERO",
    "CÓDIGO_ASOCIADO"
];

const arrayStatus = {
    'success': 'Exitoso',
    'error': 'Error',
};

const EpicSpinner = () => {
    return (<div class="orbit-spinner">
        <div className="orbit"></div>
        <div className="orbit"></div>
        <div className="orbit"></div>
    </div>);
};

const Certificado = () => {

    const [files, setFiles] = useState();
    const [headers, setHeaders] = useState(arrayHeaders);
    const [status, setStatus] = useState('');
    const [data, setData] = useState('');
    const [message, setMessage] = useState('');
    const [indexError, setIndexError] = useState(0);
    //Spin to wait load data.
    const [loading, setLoading] = useState(false);
    //Handler to change diferent Tabs
    const handleChangeTap = (value) => {
    };

    const processCSV = (str) => {

        //Now what delimiter are we using?
        let delim = str.slice(0, str.indexOf('\n')).includes(',') ? ',' : ';';

        //Split the string into an array of rows
        const rows = str.slice(str.indexOf('\n') + 1).split('\n');

        //Remove row
        rows.splice(rows.indexOf('\n'), 1);

        let BreakException = {};

        try {

            const newArray = rows.map((row, index) => {

                const values = row.split(delim);

                let obj = values.length === 18 && values.reduce((obj, value, i) => {

                    if(['CONSECUTIVO', 'ESTADO', 'CFS', 'ÁMBITO'].includes(headers[i])){
                        if(value.replace(/['"]+/g, '') === ''){
                            throw new Error(`Fila ${index + 2}: El valor de la columa "${headers[i]}" no puede estar vacio: '${value}'`);
                        }

                        if(!Number.isInteger(Number(value.replace(/['"]+/g, '')))){
                            throw new Error(`Fila ${index + 2}: El valor de la columa "${headers[i]}" no es un numero valido: '${value}'`);
                        }
                    }

                    if (['INICIO', 'CIERRE', 'NACIMIENTO'].includes(headers[i])) {
                        if (!moment(value.replace(/['"]+/g, ''), 'DD/MM/YYYY', true).isValid()) {
                            throw new Error(`Fila ${i + 2}: El valor de la columa "${headers[i]}" no es valido para ser registrado: '${value}'`);
                        }
                    }
                    if(headers[i] === 'GENERO'){
                        if(!["M","F"].includes(value.replace(/['"]+/g, '')) ){
                            throw new Error(`Fila ${index + 2}: El valor de la columa "${headers[i]}" no es valido para ser registrado: '${value}'`);
                        }
                    }

                    obj[headers[i]] = verifyData(index, headers[i], value);
                    return obj;
                }, {});
                if (!obj) {
                    if (values.length !== 18) {
                        throw new Error(`Fila ${index + 2}: El Registro posee coma (,) en alguno de sus campos o salto de linea.`);
                    }
                }
                obj.key = index;
                return obj;
            });
            return newArray;

        } catch (error) {
            AlertOk('Error!',
                'red',
                `Ha ocurrido un error al procesar el archivo: ${error.message}`,
                { ok: 'Ok' });
            setLoading(false);
            return false;
        }
    };

    const handleReadFile = (data) => {
        setLoading(true);

        setMessage('');
        setStatus('');
        setData('');
        if (data !== '' && data !== undefined) {
            const reader = new FileReader();
            reader.onload = function (e) {
                const text = e.target.result;
                if (processCSV(text)) {
                    AlertOk('Muy bien!',
                        'green',
                        `Todo ha salido bien, el archivo ha sido procesado correctamente`,
                        { ok: 'Ok' });
                }
            };
            reader.readAsText(data);
        } else {
            setFiles('');
        }
        setLoading(false);
    };

    const verifyData = (index, header, value) => {
        let BreakException = {};
        try {
            return value;
        } catch (error) {
            BreakException = {
                index: index + 1,
                message: `El valor <span className='error'>${value}</span> no es válido para el campo <span className='error'>${header}</span>`
            };
            throw BreakException;
        }
    };

    const handleSubmit = async (e) => {
        const formData = new FormData();
        formData.append('csv', files);
        const response = await post(endPointMasiveCertificate, formData, true);
        setMessage(response.message);
        setStatus(response.status);
        setData(response.data !== undefined ? response.data : '');
    };

    const handleCancel = () => {
        setFiles();
        setMessage('');
        setStatus('');
        setData('');
    };

    return <Fragment>
        <div className="row page-titles mt-3">
            <div className="col-md-10">
                <h6 className="breadcrumb-item m-b-0">Administración de Certificados de los Estudiantes</h6>
            </div>
        </div>
        <div className="row mt-3">
            <div className="card">
                <div className="card-block mt-2">
                    <div className="tab-content">
                        <Tabs defaultActiveKey="1" type="card" onChange={handleChangeTap}>
                            <TabPane tab={`Carga Masiva`} key="1" className="tab-pane p-20">
                                <form>
                                    <div className='row mb-1'>
                                        <div className="col-4 me-auto mb-1">
                                            <input
                                                type="file"
                                                name="files"
                                                className="form-control"
                                                accept=".csv"
                                                onChange={(e) => {
                                                    handleReadFile(e.target.files[0]);
                                                    setFiles(e.target.files[0]);
                                                }}
                                                required
                                            />
                                        </div>
                                        <div className='col-8 ms-auto mb-1'>
                                            <button type='button' className="btn btn-outline-primary ms-3" onClick={() => { handleSubmit(); }} >
                                                Guardar <i className="fas fa-save"></i>
                                            </button>
                                            <button type='reset' className="btn btn-outline-secondary ms-3" onClick={() => { handleCancel(); }} >
                                                Cancelar <i className="fas fa-times"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='row m-3'>
                                        <div className="col-2">{loading ? <EpicSpinner /> : ''}</div>
                                        <div className="col-10">
                                            <p>{status !== '' ? `Estatus : ${arrayStatus[status]}` : null}</p>
                                            <p>{message !== '' ? `Mensaje : ${message}` : null} </p>
                                            <p>{data !== '' ? `Asunto  : ${data}` : null}</p>
                                        </div>

                                    </div>
                                </form>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>;
};

export default Certificado;