import $ from 'jquery';

export const AlertOk = (title = '', type = '', content = '', buttons = { ok: 'Ok' }, action = () => { }) => {
    $.alert({
        icon: 'fas fa-info-circle',
        title: `<div class="fw-lighter fs-4"> ${title} </div>`,
        type: `${type}`,
        content: `<p class="fs-5 fw-normal mt-4">${content}</p>`,
        theme: 'material',
        buttons: {
            ok: {
                text: `<div class="text-dark p-2">${buttons.ok}</div>`,
                btnClass: 'btn-orange',
                action,
            },
        },
    });
};

export const AlertCancelOk = (
    title = '',
    type = '',
    content = '',
    buttons = { ok: 'Ok', cancel: 'Cancelar' },
    actionOk = () => { },
    actionCancel = () => { }
) => {
    $.alert({
        icon: 'fas fa-info-circle',
        title: `<div class="fw-lighter fs-4"> ${title} </div>`,
        type: `${type}`,
        content: `<p class="fs-5 fw-normal">${content}</p>`,
        theme: 'material',
        buttons: {
            cancel: {
                text: `<div class="text-dark p-2">${buttons.cancel}</div>`,
                btnClass: 'btn-orange',
                action: actionCancel,
            },
            ok: {
                text: `<div class="text-dark p-2">${buttons.ok}</div>`,
                btnClass: 'btn-orange',
                action: actionOk,
            },
        },
    });
};

