/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { get } from '../../Services/HttpRequest';
import { endPointTargetsByPeriod } from '../../Services/EndPoints';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function Progress(props) {
    const { id_estado, periodo, total } = props.data;
    const [state, setState] = useState('');
    const [progress, setProgress] = useState([]);
    const [estimation, setEstimation] = useState([]);

    const options = {
        indexAxis: 'y',
        responsive: true,
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        plugins: {
            legend: {
                position: 'right',
            },
            title: {
                display: true,
                //Should be to get by the variable props
                text: `Progreso de las Metas del estado ${state} en el periodo ${periodo}`,
            },
        },
    };

    const [labels, setLabels] = useState(['Total']);

    const loadData = async () => {
        let toLables = [];
        let toEstimation = [];
        let toProgress = [];
        let resTargets = await get(endPointTargetsByPeriod, { period: periodo });

        setState(resTargets.find(state => state.id_estado === id_estado).estado);

        let progressTotal = 0;
        resTargets.forEach(target => {
            progressTotal += parseInt(target.participan);
            toLables.push(target.ambito);
            toEstimation.push(target.cant_participantes);
            toProgress.push(target.participan);
        });

        setLabels([...toLables, 'Total']);
        setEstimation([...toEstimation, total]);
        setProgress([...toProgress, progressTotal]);
    };

    useEffect(() => {
        loadData();
    }, []);

    const data = {
        labels,
        datasets: [
            {
                label: 'Progreso',
                //Should be to get by the variable props
                data: progress,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Estimación',
                //Should be to get by the variable props
                data: estimation,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };

    return <Fragment>
        <Bar options={options} data={data} />
    </Fragment>;
}

export default Progress;