import React, { Fragment, useEffect, useState } from 'react';
import Datatable from '../../../../Components/Datatable';
import { endPointAllAreas } from '../../../../Services/EndPoints';
import { get } from '../../../../Services/HttpRequest';

export const Areas = (props) => {

    //Spin to wait load data.
    const [loading, setLoading] = useState(false);
    //Data to show in table.
    const [data, setData] = useState([]);

    const columns = [
        { title: '#', width: '5%', dataIndex: 'num', key: 'num' },
        { title: 'Nombre', dataIndex: 'name', key: 'name' },
        { title: 'Descripcion', dataIndex: 'description', key: 'description' },
        { title: 'ShortName', dataIndex: 'shortName', width: '10%', key: 'shortName' },
        {
            title: 'Aciones',
            width: '5%',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (data) => {
                return <>
                    <button
                        className="btn btn-info ms-2 btn-sm"
                        onClick={() => dataUpdate(data)}
                        style={{ color: '#fff' }}
                        title="Editar"
                    >
                        <i className="fas fa-pencil-alt"></i>
                    </button>
                </>;
            },
        },
    ];

    const loadData = async () => {
        const allAreas = [];
        setLoading(true);
        const areas = await get(endPointAllAreas);

        if (areas) {
            areas.forEach((area, i) => {
                allAreas.push({
                    key: `${area.id}`,
                    num: i + 1,
                    id: area.id,
                    name: area.nombre,
                    description: area.descripcion,
                    shortName: area.shortname,
                    action: area.id,
                });
            });
        }
        setLoading(false);
        setData(allAreas);
    };

    const dataUpdate = (id) => {

        const scope = data.filter(item => item.id === id)[0];
        console.log(data, id, scope);
        props.action({ id: scope.id, name: scope.name, description: scope.description, shortName: scope.shortName });
    };

    useEffect(() => {
        loadData();
    }, []);

    return <Fragment>
        <Datatable bordered columns={columns} data={data} loading={loading} />
    </Fragment>;
};
