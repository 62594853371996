import { endPointVerifyToken, endPointUserTokenAuth } from './EndPoints';
import { post } from './HttpRequest';

export const login = async (username = '', password = '') => {
    try {

        const response = await post(endPointUserTokenAuth, { username, password });

        if (response.token) {
            localStorage.setItem('authorization', response.token);
            sessionStorage.setItem('parameters', JSON.stringify(response));
        }

        return response;

    } catch (e) {

        return false;

    }
};

export const isAuthenticated = () => {

    const session = sessionStorage.getItem('parameters');
    const local = localStorage.getItem('authorization');

    if (session === null && local === null) {
        return false;
    }

    if ((session && session.length > 3) &&
        (local && local.length > 5)) {
        return true;
    }
};

export const verify = async () => {
    try {
        const response = await post(endPointVerifyToken);
        if (response === undefined) {
            logout();
        } else {
            return true;
        }
    } catch (e) {
        console.log(e.error);
        //TODO store.dispatch(ACTIONS.setHttpError(e.response.status));
    }
};

export const logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location = '/';
};