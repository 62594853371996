/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import { endPointDashboardCounters } from '../../Services/EndPoints';
import { get, post } from '../../Services/HttpRequest';

const Dashboard = () => {
    const [data, setData] = useState({});
    const [isUp, setIsUp] = useState(false);

    const loadData = async () => {
        let res = await get(endPointDashboardCounters);

        //Calulated in % enrolled & Fixed to decimals
        res.totalPercentEnrolled = (((parseFloat(res.total_matriculados) - parseFloat(res.total_matriculados_ayer)) / parseFloat(res.total_matriculados_ayer)) * 100).toFixed(2);

        if (res.total_matriculados !== res.total_matriculados_ayer) {
            setIsUp(true);
        }

        setData(res);
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <Fragment>
            <div className="row page-titles mt-3">
                <div className="col-md-10">
                    <h4 className="breadcrumb-item m-b-0">Indicadores</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-3 col-md-6">
                    <div className="card" style={{ height: 150 }}>
                        <div className="card-block m-3">
                            <h4 className="card-title">Certificados Emitidos</h4>
                            <div className="text-end">
                                <h2 className="font-light m-b-0">
                                    {data.certificados} <i className="fa fa-certificate"></i>
                                </h2>
                                <span className="text-muted">Certificados</span>
                            </div>
                            <span className="text-primary"></span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="card">
                        <div className="card-block m-3">
                            <h4 className="card-title">Total Inscritos (Via Aplicativo)</h4>
                            <div className="text-end">
                                <h2 className="font-light m-b-0">
                                    <i className={`${isUp ? 'ti-arrow-up text-success' : 'ti-minus text-muted'}`}></i> {data.total_matriculados} <i className="fa fa-users"></i>
                                </h2>
                                <span className="text-muted">Participantes</span>
                            </div>
                            <span className="text-success">{`${data.totalPercentEnrolled}%`}</span>
                            <div className="progress">
                                <div className="progress-bar bg-success" role="progressbar" style={{ width: `${data.totalPercentEnrolled}%`, height: '6px' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment >
    );
};

export default Dashboard;
