import React, { Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import header from '../../Assets/images/svg/cintillo_headers.svg';

const ModalFormation = ({ action, ...props }) => {

    const noShowPrograms = [
        'Programa Bachillerato Productivo',
        'Programa Inces Militar',
        'Programa Nacional de Aprendizaje',
        'Formación en Entidades de Trabajo',
        'Acreditación y Certificación de Saberes'
    ];

    return <Fragment>
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <img src={header} alt="" width="80%" />
                    <div>{props.data.title}</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="card-text text-justify fs-5">
                    {props.data.desciption}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={props.onHide}>Cerrar</button>
                {noShowPrograms.includes(props.data.title) ?
                    <button type="button" className="btn btn-primary" onClick={props.onHide}> Ok </button> :
                    <button type="button" className="btn btn-primary" onClick={action}> Unirme </button>
                }
            </Modal.Footer>
        </Modal>
    </Fragment>;
};

export default ModalFormation;
