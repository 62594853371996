/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect, useContext } from 'react';
import Datatable from '../../../../Components/Datatable';
import LoginContext from '../../../../context/Login/LoginContext';
import { endPointCores } from '../../../../Services/EndPoints';
import { get } from '../../../../Services/HttpRequest';
import Masive from './Masive';

export const Cores = (props) => {

    //Context
    const { state } = useContext(LoginContext);

    //Spin to wait load data.
    const [loading, setLoading] = useState(false);
    const [changeMasive, setChangeMasive] = useState(true);

    const [selectState, setSelectState] = useState('');
    const [selectScope, setSelectScope] = useState('');

    //Data to show in table.
    const [data, setData] = useState([]);
    //Filters
    const [dataFilter, setDataFilter] = useState([]);
    const [statesFilter, setStatesFilter] = useState([]);
    const [scopesFilter, setScopesFilter] = useState([]);

    const columns = [
        { title: '#', width: '5%', dataIndex: 'num', key: 'num' },
        { title: 'Codigo', dataIndex: 'code', key: 'code' },
        { title: 'Nombre', dataIndex: 'name', key: 'name' },
        { title: 'Direccion', dataIndex: 'address', key: 'address' },
        {
            title: 'Aciones',
            width: '5%',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (data) => {
                return <>
                    <button
                        className="btn btn-info ms-2 btn-sm"
                        onClick={() => dataUpdate(data)}
                        style={{ color: '#fff' }}
                        title="Editar"
                    >
                        <i className="fas fa-pencil-alt"></i>
                    </button>
                </>;
            },
        },
    ];

    const loadData = async () => {
        let allCores = [];
        let states = [];
        let scopes = [];

        setLoading(true);
        const response = await get(endPointCores);
        if (response) {
            response.forEach((core, i) => {
                allCores.push({
                    key: `${core.id}`,
                    id: core.id,
                    num: i + 1,
                    code: core.codigo,
                    name: core.nombre,
                    address: core.direccion,
                    action: core.id,
                });

                if (!states.some(i => i.value === core.estado)) {
                    states.push({ id: core.id_stados, value: core.estado });
                }

                if (!scopes.some(i => i.value === core.ambito)) {
                    scopes.push({ id: core.id_ambito, value: core.ambito });
                }

            });
        }

        setLoading(false);
        setDataFilter(response);
        setStatesFilter(states);
        setScopesFilter(scopes);
        setData(allCores);
    };

    const dataUpdate = (id) => {

        let core = dataFilter.find(i => i.id === id);

        props.action({
            id: core.id,
            code: core.codigo,
            name: core.nombre,
            address: core.direccion,
            state: core.id_estado,
            scope: core.id_ambito,
            township: core.id_municipios,
            parish: core.id_parroquias,
        });
    };

    /**
     * Handles filtering of states based on the given value.
     *
     * @param {type} value - the value to filter the states by
     * @return {type} undefined
     */
    const handleStatesFilter = (value) => {
        let states = [];
        let cores = [];
        let scopes = [];
        setLoading(true);
        states = value !== '' ? dataFilter.filter(i => i.estado === value) : dataFilter;
        states.forEach((state, i) => {
            cores.push({
                key: `${state.id}`,
                id: state.id,
                num: i + 1,
                code: state.codigo,
                name: state.nombre,
                address: state.direccion,
                action: state.id,
            });
            if (!scopes.some(i => i.value === state.ambito)) {
                scopes.push({ id: state.id_ambito, value: state.ambito });
            }
        });
        setLoading(false);

        setSelectState(value);
        setSelectScope('');
        setData(cores);
        setScopesFilter(scopes !== null ? scopes : []);
    };

    const handleScopesFilter = (value) => {

        let cores = [];
        let scopes = [];

        if(value !== '' && selectState !== ''){
            scopes = dataFilter.filter(i => i.id_ambito === value && i.estado === selectState);
        }else{
            scopes = value !== '' ? dataFilter.filter(i => i.id_ambito === value) : selectState !== '' ? dataFilter.filter(i => i.estado === selectState) : dataFilter;
        }

        setLoading(true);
        scopes.forEach((scope, i) => {
            cores.push({
                key: `${scope.id}`,
                id: scope.id,
                num: i + 1,
                code: scope.codigo,
                name: scope.nombre,
                address: scope.direccion,
                action: scope.id,
            });

        });
        setSelectScope(value);
        setLoading(false);
        setData(cores);
    };

    const handleMasive = () => {
        setChangeMasive(!changeMasive);
        loadData();
    };

    useEffect(() => {
        loadData();
    }, []);

    return <Fragment>
        {changeMasive ? <>
            <div className="row mb-1">
                <div className="col-md-2 ">
                    <select
                        name='estado'
                        className="form-select"
                        onChange={(e) => handleStatesFilter(e.target.value)}
                        value={selectState}
                    >
                        <option value=""> - Estado -</option>
                        {statesFilter.map((state, i) =>
                            <option key={i} value={state.id}> {state.value} </option>
                        )}
                    </select>
                </div>
                <div className="col-md-2 ">
                    <select
                        name="ambito"
                        className="form-select"
                        onChange={(e) => handleScopesFilter(e.target.value)}
                        value={selectScope}
                    >
                        <option value=""> - Ambitos -</option>
                        {scopesFilter.map((scope, i) =>
                            <option key={i} value={scope.id}> {scope.value} </option>
                        )}
                    </select>
                </div>
                <div className="col-3 ms-auto mb-1">
                    <button className="btn btn-outline-primary me-1" onClick={() => handleMasive()}> Masivo <i className="fas fa-layer-group"></i></button>
                    <button className="btn btn-outline-success" onClick={() => props.add()}> Agregar <i className="fas fa-layer-group"></i></button>
                </div>
            </div>
            <Datatable bordered columns={columns} data={data} loading={loading} />
        </>
            : <Masive action={handleMasive} />}
    </Fragment>;
};
