import React, { Fragment, useState } from 'react';
import { Modal } from 'react-bootstrap';
import header from '../../Assets/images/svg/cintillo_headers.svg';
import Config from '../../Services/Config';
import { endPointGetPerson, endPointGetCertificates } from '../../Services/EndPoints';
import { get, post } from '../../Services/HttpRequest';


const headerModal = {
    backgroundImage: `url("${header}")`,
    width: '100%',
    backgroundRepeat: 'no-repeat',
    let: 0,
    top: 0,
    paddingBottom: 0,
};

const ModalCertificateRequest = ({ action = () => { }, ...props }) => {

    console.log({ action, ...props });
    //const [data, setData] = useState({ ...props.data });
    const [dataSource, setDataSource] = useState([]);
    const [listOfCertificate, setListOfCertificate] = useState([]);
    const [alert, setAlert] = useState(false);

    const handleChange = async (e) => {
        const res = await post(endPointGetPerson, { id_person: e.target.value });
        if (res) {
            setAlert(false);
            setDataSource(res);

            const certificates = await get(endPointGetCertificates, { id_person: e.target.value });
            console.log(certificates);
            if (certificates) {
                setListOfCertificate(certificates);
            }
        } else {
            setAlert(true);
            setDataSource([]);
        }
    };

    const handleClose = () => {
        setDataSource([]);
        setListOfCertificate([]);
        props.onHide();
    };

    return <Fragment>
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton style={headerModal}>
                <Modal.Title id="contained-modal-title-vcenter" >
                    <div className="row" >
                        <div className="col-12" style={{ marginTop: 60, marginLeft: 60 }}>
                            <p className='h4 text-start'>Certificados</p>
                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row mt-2">
                    <div className="col-3">
                        <label htmlFor="">Cedula:</label>
                    </div>
                    <div className="col-5">
                        <div class="input-group">
                            <input
                                className='form-control ms-3'
                                type="text"
                                placeholder='Cedula'
                                onBlur={handleChange}
                            />
                            <button class="btn btn-outline-secondary input-group-text"> Buscar </button>
                        </div>

                    </div>
                    <div className="col-4">
                        {alert && <div className="badge bg-danger fw-normal m-2" role="alert">
                            No se encontro el usuario
                        </div>}
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-3">
                        Nombre Apellidos:
                    </div>
                    <div className="col-5">
                        <p className="card-text">
                            <span className="text-muted fs-6 fst-italic fw-normal ms-3">{`${dataSource.nombres || ''} ${dataSource.apellidos || ''}`}</span>
                        </p>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-3 ">
                        Correo:
                    </div>
                    <div className="col-5">
                        <p className="card-text">
                            <span className="text-muted fs-6 fst-italic fw-normal ms-3">{dataSource.correo || ''}</span>
                        </p>
                    </div>
                </div>
                <div className="row mt-3" >
                    <div className="col-3">
                        Certificados:
                    </div>
                    <div className="col-9 text-muted fw-normal">
                        {listOfCertificate.length > 0 ?
                            listOfCertificate.map((item, index) => {
                                return <div key={index} className="fw-normal ms-3">
                                    <i className="far fa-file-pdf text-danger me-3"></i> <a href={`${Config.API_URL}generatecertificate?certificate=${item.idCertificate}`} target="_blank" rel="noopener noreferrer"> {item.courseName}</a>
                                </div>;
                            }) : <span className='text-danger mt-2 ms-3'>Participante no posee Certificado</span>
                        }
                    </div>

                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={handleClose}>Cerrar</button>
            </Modal.Footer>
        </Modal>
    </Fragment>;
};

export default ModalCertificateRequest;