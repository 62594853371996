/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { Fragment, useContext, useState, useEffect } from "react";
import LoginContext from "../../context/Login/LoginContext";
import { Tabs } from "antd";
import Enrollees from "./Enrolled/Enrollees";
import { Participant } from "./Enrolled/Participant";
import { ListStudens } from "./Enrolled/ListStudens";
import FormParticipant from "../../Components/Forms/FormParticipant";

const { TabPane } = Tabs;

const Matriculado = () => {
    const [showListStudents, setShowListStudents] = useState(false);
    const [dataGroup, setDataGroup] = useState({});

    return (
        <Fragment>
            <div className="row page-titles mt-3">
                <div className="col-md-10">
                    <h6 className="breadcrumb-item m-b-0">
                        Administración de las Participantes y Matriculados
                    </h6>
                </div>
            </div>
            <div className="row mt-3">
                <div className="card">
                    <div className="card-block mt-2">
                        <div className="tab-content">
                            <Tabs
                                defaultActiveKey="1"
                                type="card"
                                items={[
                                    {
                                        key: "1",
                                        label: "Participantes",
                                        children: <FormParticipant />,
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

/* <TabPane tab="Lista de Participantes" key="2" className="tab-pane p-20">
        {showListStudents ? <ListStudens data={dataGroup} showList={setShowListStudents} /> :
            <Participant showList={setShowListStudents} data={setDataGroup} />}
    </TabPane>
    <TabPane tab="Matriculados" key="3" className="tab-pane p-20">
        <Enrollees />
    </TabPane> */
export default Matriculado;
