/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import header from '../../Assets/images/svg/cintillo_headers.svg';
import { endPointCourseContent } from '../../Services/EndPoints';
import { post } from '../../Services/HttpRequest';

const ModalCourses = ({ action = () => { }, ...props }) => {

    const [data, setData] = useState([]);

    const loadData = async () => {
        const res = await post(endPointCourseContent, { shortname: props.data.shortname });
        setData(res);
    };

    useEffect(() => {
        loadData();
    }, [props.data.shortname]);

    return <Fragment>
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ fontSize: '12px' }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <img className='img-fluid' src={header} alt="" width="25%"  />
                    <div className='text-muted fs-6'>{props.data.nombre}</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="card-text text-justify fs-6">
                    Contenido:
                </p>
                <ol className="list-group list-group-numbered">
                    {(Array.isArray(data) && data.map((item, index) =>
                        <li className="list-group-item" key={index}>
                            <span className='text-muted'>{item.contenido}</span>
                        </li>)) || (<li>No posee contenido</li>)
                    }
                </ol>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={props.onHide}>Cerrar</button>
                <button type="button" className="btn btn-primary" onClick={props.onHide}>Ok</button>
            </Modal.Footer>
        </Modal>


    </Fragment>;
};

export default ModalCourses;

