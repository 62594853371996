import React, { Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import header from '../../Assets/images/svg/cintillo_headers.svg';
import Progress from '../Chart/Progress';

function ModalTarget({ action, ...props }) {
    return <Fragment>
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <img src={header} alt="" width="80%" />
                    <div>{props.data.title}</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Progress data={props.data} />
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-primary" onClick={props.onHide}> Regresar </button>
            </Modal.Footer>
        </Modal>
    </Fragment>;
}

export default ModalTarget;