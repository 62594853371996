/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect, useContext } from 'react';
import LoginContext from '../../context/Login/LoginContext';
import Datatable from '../../Components/Datatable';
import FormAddUser from '../../Components/Forms/FormAddUser';
import { endPointGetUsers, endPointResetUserPass, endPointUserChangeAccess } from '../../Services/EndPoints';
import { get, post } from '../../Services/HttpRequest';
import { AlertOk } from '../../Components/Alert/Alert';

const Usuarios = () => {
    //Context
    const { rol } = useContext(LoginContext);
    const [addUser, setAddUser] = useState(true);
    const [loading, setLoading] = useState(false);
    const [dataSources, setDataSuorces] = useState([]);

    const columns = [
        {
            title: '#',
            dataIndex: 'num',
            key: 'num',
        },
        {
            title: 'Nombres',
            dataIndex: 'names',
            key: 'names',
        },
        {
            title: 'Apellidos',
            dataIndex: 'surnames',
            key: 'surnames',
        },
        {
            title: 'Correo',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Usuario',
            dataIndex: 'user',
            key: 'user',
            render: text => <span className='fst-italic fw-bold'>{text}</span>
        },
        {
            title: 'Estatus',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: text =>
                <span
                    className={`badge ${text === 't' ? 'bg-success' : 'bg-danger'}`}
                    style={{ fontSize: '13px' }}
                >
                    {text === 't' ? 'Activado' : 'Desactivado'}
                </span>
        },
        {
            title: 'Region',
            dataIndex: 'region',
            key: 'region',
            render: text => <span className='fst-italic fw-bold'>{text}</span>
        },
        {
            title: 'Acciones',
            dataIndex: 'actions',
            key: 'actions',
            align: 'center',
            width: '10%',
            render: data =>
                <>
                    <button
                        className='btn btn-secondary btn-sm me-2'
                        title="Reestablecer Contraseña"
                        onClick={() => resetPassword(data.username)}
                    >
                        <i className='fa fa-sync' />
                    </button>
                    <button
                        className={`btn ${data.status === 't' ? 'btn-danger' : 'btn-warning'} btn-sm me-2`}
                        title={`${data.status === 't' ? 'Desactivar' : 'Activar'}  Usuario`}
                        onClick={() => handleAccess(data.username, data.status)}
                    >
                        <i className={`fa ${data.status === 't' ? 'fa-user-times' : 'fa-user-check'}`} />
                    </button>
                    <button
                        className='btn btn-2 btn-info btn-sm'
                        title='Editar Usuario'
                        style={{ color: '#fff' }}
                    >
                        <i className='fa fa-pencil-alt' />
                    </button>
                </>
        },
    ];

    const loadUsers = async () => {
        let data = [];
        setLoading(true);
        let resUsers = await get(endPointGetUsers);
        if (['17'].includes(rol)) {
            resUsers = resUsers.filter((e) => e.id_rol === "16");
        }
        if (['11'].includes(rol)) {
            resUsers = resUsers.filter((e) => e.id_rol !== "10");
        }
        if (resUsers) {
            resUsers.forEach((user, i) => {
                data.push({
                    key: `${i + 1}`,
                    num: `${i + 1}`,
                    user: user.username,
                    names: user.nombres,
                    surnames: user.apellidos,
                    email: user.correo,
                    region: user.region,
                    status: user.activado,
                    actions: {
                        status: user.activado,
                        username: user.username
                    }
                });
            });
            setDataSuorces(data);
        }
        setLoading(false);
    };

    const changedRegister = (value = 0) => {

        if (value === 2) {
            loadUsers();
        }
        setAddUser(!addUser);
    };

    const resetPassword = async (username) => {

        const res = await post(endPointResetUserPass, { username });

        if (res.msg === 'Password Reseted') {
            AlertOk(
                'Información!',
                'blue',
                'La Contraseña ha sido restablecida de forma satisfactoria.',
                { ok: 'Ok' },
                () => { loadUsers(); }
            );
        }

    };

    const handleAccess = async (username, status) => {
        const res = await post(endPointUserChangeAccess, { username, status });
        if (res.msg === 'Modified user access') {
            AlertOk(
                'Información!',
                'blue',
                `Se ha ${status === 't' ? 'desactivado' : 'activado'} el usuario de forma satisfactoria.`,
                { ok: 'Ok' },
                () => { loadUsers(); }
            );
        }
    };

    useEffect(() => {
        loadUsers();
    }, []);

    return (
        <Fragment>
            {addUser ?
                (<>
                    <div className="row page-titles mt-3">
                        <div className="col-md-10 ">
                            <h4 className="breadcrumb-item m-b-0">Lista de Usuarios</h4>
                        </div>
                        <div className="col-md-2 ms-auto">
                            <button className="btn btn-outline-success" onClick={() => setAddUser(!addUser)}>Agregar <i className="fas fa-user-plus"></i></button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Datatable bordered columns={columns} data={dataSources} size={"middle"} loading={loading} />
                        </div>
                    </div>
                </>) :
                (<>
                    <div className="row page-titles mt-3">
                        <div className="col-md-10 ">
                            <h4 className="m-b-0">Agregar Usuario</h4>
                        </div>
                        <div className="col-md-2 ms-auto">
                            <button className="btn btn-outline-secondary waves-effect waves-light" onClick={() => setAddUser(!addUser)}>Regresar <i className="fas fa-backward"></i></button>
                        </div>
                    </div>
                    <div className="row">
                        <FormAddUser action={changedRegister} />
                    </div></>)
            }
        </Fragment>
    );
};

export default Usuarios;
