/**
 * Headers
 */
import React, { useReducer } from 'react';
/**
 * useContext
 */
import LoginReducer from './LoginReducer';
import LoginContext from './LoginContext';

const LoginStates = (props) => {
    const storage = sessionStorage.getItem('parameters');

    const initialUser = storage
        ? JSON.parse(storage)
        : { username: '', token: '', rol: '', cfs: '', state: '', fullname: '' };

    const initialState = {
        username: initialUser.username,
        token: initialUser.token,
        rol: initialUser.rol,
        cfs: initialUser.cfs,
        state: initialUser.state,
        fullname: initialUser.fullname
    };

    const [state, dispatch] = useReducer(LoginReducer, initialState);

    return (
        <LoginContext.Provider
            value={{
                username: state.username,
                token: state.token,
                rol: state.rol,
                cfs: state.cfs,
                state: state.state,
                fullname: state.fullname,
                dispatch,
            }}
        >
            {props.children}
        </LoginContext.Provider>
    );
};

export default LoginStates;