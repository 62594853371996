/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { get, post } from '../../../../Services/HttpRequest';
import { AlertOk } from '../../../../Components/Alert/Alert';
import { endPointAllAreas, endPointCreateCore, endPointParishes, endPointStates, endPointTownships, endPointUpdateCore } from '../../../../Services/EndPoints';
import LoginContext from '../../../../context/Login/LoginContext';

export const FormCores = (props) => {

    //Context
    const { rol, state } = useContext(LoginContext);

    const [states, setStates] = useState([]);
    const [townships, setTownships] = useState([]);
    const [parishes, setParishes] = useState([]);
    const [scopes, setScopes] = useState([]);
    const [dataForm, setDataForm] = useState({
        id: '',
        code: '',
        name: '',
        address: '',
        state: '',
        scope: '',
        township: '',
        parish: '',
    });
    const [API, setApi] = useState('');
    const [isCreate, setIsCreate] = useState(true);

    const Schema = Yup.object().shape({
        code: Yup.string()
            .required('El código es requerido'),
        name: Yup.string()
            .required('El nombre es requerido'),
        address: Yup.string()
            .required('La dirección es requerida'),
        state: Yup.string()
            .required('El estado es requerido'),
        scope: Yup.string()
            .required('El ámbito es requerido'),
        township: Yup.string()
            .required('El municipio es requerido'),
        parish: Yup.string()
            .required('La parroquia es requerida'),
    });

    const loadStates = async () => {
        let response = await get(endPointStates);
        if (response) {
            setStates(response);
        }
        setTownships([]);
        setParishes([]);
    };

    const loadTownships = async (id) => {
        let response = id !== '' ? await get(endPointTownships, { id_state: id }) : false;
        if (response) {
            setTownships(response);
        } else {
            setTownships([]);
        }
        setParishes([]);
    };

    const loadParishes = async (id) => {

        let response = id !== '' ? await get(endPointParishes, { id_township: id }) : false;

        if (response) {
            setParishes(response);
        }
    };

    const loadScopes = async () => {
        let response = await get(endPointAllAreas);
        if (response) {
            setScopes(response);
        }
    };

    useEffect(() => {

        const { id, code, name, address, state, scope, township, parish } = props.data;


        loadStates();
        loadScopes();

        setDataForm({
            id: id || '',
            code: code || '',
            name: name || '',
            address: address || '',
            state: state || '',
            scope: scope || '',
            township: township || '',
            parish: parish || '',
        });

        setApi(id === '' ? endPointCreateCore : endPointUpdateCore);
        setIsCreate(id === '' ? true : false);

        if (id !== '') {
            loadTownships(state);
            loadParishes(township);

            AlertOk(
                'Informacion!',
                'red',
                `Debe estar atento cuando modifique la <strong>dirección</strong> de este registro.`,
                { ok: 'Ok' },
            );
        }
        if (['17'].includes(rol)) {
            loadTownships(state);
        }
    }, []);

    return <Fragment>
        <div className="card card-block p-5 ">
            <h4>Formulario de Organizaciones</h4>
            <Formik
                initialValues={dataForm}
                enableReinitialize={true}
                validationSchema={Schema}
                onSubmit={async (values, { resetForm }) => {
                    values.id = dataForm.id;
                    const response = await post(API, values);
                    resetForm({ values: '' });
                    if (response === 'created') {
                        AlertOk(
                            'Informacion!',
                            'purple',
                            `Muy bien! <spam className='mt-2'>El Nucleo ha sido creado satisfactoriamente.</spam> `,
                            { ok: 'Ok' },
                            () => { props.action(true); }
                        );
                        return;
                    }
                    if (response === 'updated') {
                        AlertOk(
                            'Informacion!',
                            'purple',
                            `Muy bien! <spam className='mt-2'>El Nucleo ha sido actualizado satisfactoriamente.</spam> `,
                            { ok: 'Ok' },
                            () => { props.action(true); }
                        );
                        return;
                    }
                    if (!response) {
                        AlertOk(
                            'Informacion!',
                            'red',
                            `Alerta! <spam className='mt-2'>Algo salio mal.</spam> `,
                            { ok: 'Ok' },
                            () => { props.action(true); }
                        );
                        return;
                    }
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-5">
                                    <div className="form-group">
                                        <label htmlFor="state">Estado</label>
                                        <Field name="state" >
                                            {({ field, props, form, meta }) => (
                                                <select
                                                    {...field}
                                                    className={`form-select ${errors.state && touched.state && 'is-invalid'}`}
                                                    onChange={(e) => {
                                                        form.setFieldValue(field.name, e.target.value);
                                                        loadTownships(e.target.value);
                                                    }}
                                                    disabled={['17'].includes(rol)}
                                                >
                                                    <option value="">Seleccione un Estado</option>
                                                    {states.map((state, index) => {
                                                        return <option key={index} value={state.id}>{state.estado}</option>;
                                                    })}
                                                </select>
                                            )}
                                        </Field>
                                        {errors.state && touched.state && <div className="invalid-feedback">{errors.state}</div>}
                                    </div>
                                </div>
                                <div className="col-5">
                                    <div className="form-group">
                                        <label htmlFor="scope">Ámbito</label>
                                        <Field name="scope">
                                            {({ field, props, form, meta }) => (
                                                <select
                                                    className={`form-select ${errors.scope && touched.scope && 'is-invalid'}`}
                                                    {...field}
                                                    {...props}
                                                >
                                                    <option value="">Seleccione un Ámbito</option>
                                                    {scopes.map((scope, index) => {
                                                        return <option key={index} value={scope.id}>{scope.nombre}</option>;
                                                    })}
                                                </select>
                                            )}
                                        </Field>
                                        {errors.scope && touched.scope && <div className="invalid-feedback">{errors.scope}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <div className="form-group">
                                        <label htmlFor="code">Código</label>
                                        <Field name="code" type="text" className={`form-control ${errors.code && touched.code && 'is-invalid'}`} />
                                        {errors.code && touched.code && <div className="invalid-feedback">{errors.code}</div>}
                                    </div>
                                </div>
                                <div className="col-7">
                                    <div className="form-group">
                                        <label htmlFor="name">Nombre</label>
                                        <Field name="name" type="text" className={`form-control ${errors.name && touched.name && 'is-invalid'}`} />
                                        {errors.name && touched.name && <div className="invalid-feedback">{errors.name}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-10">
                                    <div className="form-group">
                                        <label htmlFor="address">Dirección</label>
                                        <Field name="address" type="text" className={`form-control ${errors.address && touched.address && 'is-invalid'}`} />
                                        {errors.address && touched.address && <div className="invalid-feedback">{errors.address}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-5">
                                    <div className="form-group">
                                        <label htmlFor="township">Municipio</label>
                                        <Field name="township" >
                                            {({ field, props, form, meta }) => (
                                                <select
                                                    {...field}
                                                    className={`form-select ${errors.township && touched.township && 'is-invalid'}`}
                                                    onChange={(e) => {
                                                        form.setFieldValue(field.name, e.target.value);
                                                        loadParishes(e.target.value);
                                                    }}
                                                >
                                                    <option value="">Seleccione un Municipio</option>
                                                    {townships.map((township, index) => {
                                                        return <option key={index} value={township.id}>{township.municipio}</option>;
                                                    })}
                                                </select>
                                            )}
                                        </Field>
                                        {errors.township && touched.township && <div className="invalid-feedback">{errors.township}</div>}
                                    </div>
                                </div>
                                <div className="col-5">
                                    <div className="form-group">
                                        <label htmlFor="parish">Parroquia</label>
                                        <Field name="parish" >
                                            {({ field, props, form, meta }) => (
                                                <select
                                                    {...field}
                                                    className={`form-select ${errors.parish && touched.parish && 'is-invalid'}`}
                                                    onChange={(e) => {
                                                        form.setFieldValue(field.name, e.target.value);
                                                    }}
                                                >
                                                    <option value="">Seleccione una Parroquia</option>
                                                    {parishes.map((parish, index) => {
                                                        return <option key={index} value={parish.id}>{parish.parroquia}</option>;
                                                    })}
                                                </select>
                                            )}
                                        </Field>
                                        {errors.parish && touched.parish && <div className="invalid-feedback">{errors.parish}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-4 ml-5 ms-auto">
                                    <button
                                        className="btn btn-inverse m-r-10 me-1"
                                        onClick={() => { props.action(true); }}
                                    >
                                        Cancelar <i className="fas fa-undo" />
                                    </button>

                                    <button className="btn btn-success" type="submit" disabled={isSubmitting}> {isCreate ? 'Registrar' : 'Actualizar'} <i className="far fa-paper-plane" /></button>

                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    </Fragment>;
};
