/**
 * Headers
 */
import React, { useReducer } from 'react';
/**
 * useContext
 */
import LayoutReducer from './LayoutReducer';
import LayoutContext from './LayoutContext';

const LayoutStates = (props) => {
    const storage = sessionStorage.getItem('layout');
    const initialLayout = storage
        ? JSON.parse(storage)
        : {
            navbar: false,
            sidebar: false,
            miniSidebar: false,
            content: false,
            footer: false,
        };

    const initialState = {
        navbar: initialLayout.navbar,
        sidebar: initialLayout.sidebar,
        miniSidebar: initialLayout.miniSidebar,
        content: initialLayout.content,
        footer: initialLayout.footer,
    };

    const [state, dispatchLayout] = useReducer(LayoutReducer, initialState);

    return (
        <LayoutContext.Provider
            value={{
                navbar: state.navbar,
                sidebar: state.sidebar,
                miniSidebar: state.miniSidebar,
                content: state.content,
                footer: state.footer,
                dispatchLayout,
            }}
        >
            {props.children}
        </LayoutContext.Provider>
    );
};

export default LayoutStates;