/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { post } from '../../../../Services/HttpRequest';
import { AlertOk } from '../../../../Components/Alert/Alert';
import { endPointCreateArea, endPointUpdateArea } from '../../../../Services/EndPoints';

export const FormAreas = (props) => {

    const { id, name, description, shortName } = props.data;

    const [dataForm, setDataForm] = useState({
        id: id || '',
        name: name || '',
        description: description || '',
        shortName: shortName || '',
    });

    const API = id === '' ? endPointCreateArea : endPointUpdateArea;

    const Schema = Yup.object().shape({
        name: Yup.string()
            .required('El nombre es requerido'),
        description: Yup.string()
            .required('La descripción es requerida'),
        shortName: Yup.string()
            .required('El nombre corto es requerido'),
    });

    return <Fragment>
        <div className="card card-block p-5 ">
            <h4>Formulario de Carga de Ámbitos</h4>
            <Formik
                initialValues={dataForm}
                validationSchema={Schema}
                onSubmit={async (values, { resetForm }) => {
                    values.id = dataForm.id;
                    const response = await post(API, values);
                    resetForm({ values: '' });
                    if (response === 'created') {
                        AlertOk(
                            'Informacion!',
                            'purple',
                            `Muy bien! <spam className='mt-2'>El Ámbito ha sido creado satisfactoriamente.</spam> `,
                            { ok: 'Ok' },
                            () => { props.action(true); }
                        );
                        return;
                    }
                    if (response === 'updated') {
                        AlertOk(
                            'Informacion!',
                            'purple',
                            `Muy bien! <spam className='mt-2'>El Ámbito ha sido actualizado satisfactoriamente.</spam> `,
                            { ok: 'Ok' },
                            () => { props.action(true); }
                        );
                        return;
                    }
                    if (!response) {
                        AlertOk(
                            'Informacion!',
                            'red',
                            `Alerta! <spam className='mt-2'>Algo salio mal.</spam> `,
                            { ok: 'Ok' },
                            () => { props.action(true); }
                        );
                        return;
                    }
                }}
                enableReinitialize
            >
                {({ handleSubmit, resetForm, isSubmitting, errors, touched }) => {
                    return (
                        <Form className="mt-1" onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-4">
                                    <label htmlFor=""> Nombre </label>
                                    <Field name="name" type="text" className={`form-control ${errors.name && touched.name && 'is-invalid'}`} placeholder="Nombre del Ámbito" />
                                    {errors.name && touched.name ? (
                                        <div className="input-feedback">{errors.name}</div>
                                    ) : null}
                                </div>
                                <div className="col-2">
                                    <label htmlFor=""> Nombre Corto </label>
                                    <Field name="shortName" type="text" className={`form-control ${errors.shortName && touched.shortName && 'is-invalid'}`} placeholder="Nombre Corto" />
                                    {errors.shortName && touched.shortName ? (
                                        <div className="input-feedback">{errors.shortName}</div>
                                    ) : null}
                                </div>
                                <div className="col-6">
                                    <label htmlFor=""> Descripción </label>
                                    <Field name="description" type="text" className={`form-control ${errors.description && touched.description && 'is-invalid'}`} placeholder="Descripción del Ámbito" />
                                    {errors.description && touched.description ? (
                                        <div className="input-feedback">{errors.description}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-4 ml-5 ms-auto">
                                    <button
                                        className="btn btn-inverse m-r-10 me-1"
                                        onClick={() => { props.action(true); }}
                                    >
                                        Cancelar <i className="fas fa-undo" />
                                    </button>

                                    <button className="btn btn-success" type="submit" disabled={isSubmitting}> Agregar <i className="far fa-paper-plane" /></button>

                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    </Fragment>;
};
