/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { Field, Formik } from 'formik';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import {
    endPointCheckEmailPerson,
    endPointCoursesParticipant,
    endPointCreatePerson,
    endPointGetPerson,
    endPointOfficeCourses,
    endPointOfficesStates,
    endPointStates,
    endPointStatusParticipant
} from '../../Services/EndPoints';
import { get, post } from '../../Services/HttpRequest';
import { AlertOk } from '../Alert/Alert';

const Schema = Yup.object().shape({
    cardId: Yup.string().required('Requerida').trim().matches(new RegExp(`^[V|E|P]-[0-9]{5,11}$`, "gi"), 'Ejem: [V|E|P]-12345678'),
    names: Yup.string().required('Requerido'),
    surnames: Yup.string().required('Requerido'),
    email: Yup.string().email('Debe ser un Correo Valido').required('Requerido'),
    phone: Yup.string().required('N° de Teléfono requerido').matches(new RegExp("[0-9]{4} [0-9]{3} [0-9]{4}$", "g"), 'N° de Teléf. incorrecto'),
    state: Yup.string().required('Requerido'),
    cfs: Yup.string().required('Requerido'),
    opt1: Yup.string().required('Opción Formativa Requerida'),
    opt2: Yup.string(),
    opt3: Yup.string(),
});

const Cedula = ({ field, form, action, ...props }) => {
    const formatChars = {
        'a': '[VEvePp]',
        '9': '[0-9]',
    };
    return <InputMask
        mask="a-99999999999"
        maskChar=' '
        formatChars={formatChars}
        style={{ textTransform: 'uppercase' }}
        {...props}
        onChange={(e) => {
            let value = e.target.value.trim();
            if (value !== '-' && value !== '') {
                form.setFieldValue(field.name, value);
            } else {
                form.setFieldError(field.name, 'Requerida');
            }
        }}
    />;
};

const Email = ({ field, form, ...props }) => {
    return <input {...field} {...props} type="email" />;
};

const Telefono = ({ field, form, ...props }) => {
    return <InputMask
        mask="9999 999 9999"
        maskChar=' '
        {...field}
        {...props}
    />;
};

const FormsCandidate = ({ action, programs }) => {
    const [dataForm, setDataForm] = useState({
        cardId: '',
        names: '',
        surnames: '',
        email: '',
        phone: '',
        state: '',
        cfs: '',
        opt1: '',
        opt2: '',
        opt3: '',
    });
    const [states, setStates] = useState([]);
    const [offices, setOffices] = useState([]);
    const [courses, setCourses] = useState([]);
    const faildErrors = [
        'Error: Network Error',
        'Error: Request failed with status code 500'
    ];

    //load states
    const loadData = async () => {
        let estados = [];

        const resStates = await get(endPointStates);

        if (resStates.toString() !== 'Error: Network Error') {

            resStates.forEach(state => {
                if (state.id !== '25') {
                    estados.push({
                        id: state.id,
                        value: state.estado
                    });
                }
            });
            estados.sort((a, b) => {
                let nameA = a.value.toUpperCase();
                let nameB = b.value.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
            setStates([...estados]);
        } else {
            AlertOk(
                'Información!',
                'orange',
                'En estos momentos, el servidor esta tardando en responder. Intenta más tarde!',
                { ok: 'Ok' },
                () => { programs(); }
            );
        }
    };

    //Load training centers
    const loadOffice = async (id_state) => {
        setOffices([]);
        setCourses([]); //Reset Courses
        if (id_state !== "") {
            let sedes = [{
                id: '',
                value: '- Seleccione -'
            }];
            const resOffices = await get(endPointOfficesStates, { id_state });
            resOffices.forEach(office => {
                sedes.push({
                    id: office.id,
                    value: office.nombre
                });
            });
            setOffices(sedes);
        }
    };

    //Load active courses by training centers
    const loadCourses = async (id_office, form) => {
        if (id_office !== '') {
            let theCourse = [{
                id: '',
                value: '- Seleccione -'
            }];

            const resCourses = await get(endPointOfficeCourses, { id_office });

            if (resCourses) {
                resCourses.forEach(course => {
                    theCourse.push({
                        id: course.id_cur_activo,
                        value: course.curso
                    });
                });

                form.setFieldValue('opt1', '');
                form.setFieldValue('opt2', '');
                form.setFieldValue('opt3', '');
                setCourses(theCourse);

            } else {
                AlertOk('Atencion!', 'purple', 'Acutalemente este Centro de Formación no posee Opciones Formativas activas', { ok: 'Ok' });
            }
        };

    };

    const selectionCourses = (opt, opt1, opt2) => {
        let listCourses = [];

        if (!listCourses.includes(opt)) {
            listCourses.push(opt);
        }

        if (!listCourses.includes(opt1) && opt1 !== '') {
            listCourses.push(opt1);
        }

        if (!listCourses.includes(opt2) && opt2 !== '') {
            listCourses.push(opt2);
        }

        return listCourses;
    };

    const findPerson = async (value, resetForm) => {
        let res = {};
        if (value !== '') {
            let nac = value.split('-')[0];
            let id_person = value.split('-')[1].trim();
            const regEx = ['V', 'v'].includes(nac) ?
                new RegExp(`^[V]-[0-9]{5,8}$`, "gi") :
                new RegExp(`^[P|E]-[0-9]{5,11}$`, "gi");
            if (regEx.test(value.trim())) {
                //Ask if the participant have a enroll
                const resStatus = await get(endPointStatusParticipant, { id_person });
                //Get information of the participant
                const resPerson = await post(endPointGetPerson, { id_person });
                if (!resStatus) {
                    res = {
                        cardId: resPerson ? `${resPerson.nac}-${resPerson.cedula}` : value,
                        names: resPerson ? resPerson.nombres : '',
                        surnames: resPerson ? resPerson.apellidos : '',
                        email: resPerson ? resPerson.correo : '',
                        phone: resPerson ? resPerson.telefono : '',
                        state: '',
                        cfs: '',
                        opt1: ''
                    };
                    setDataForm(res);
                } else {
                    resetForm({ values: '' });
                    AlertOk('Atencion!', 'purple', 'Ya posee formación registrada', { ok: 'Ok' }, programs);
                }
            } else {
                AlertOk(
                    'Advertencia!',
                    'orange',
                    `El documento de identificación ${value.trim()} no cumple con lo requerido!`,
                    { ok: 'Ok' }
                );
            }
        }
    };

    const checkEmail = async (email) => {
        let cardId = dataForm.cardId;
        if (email !== '') {
            const res = await get(endPointCheckEmailPerson, { cardId, email });
            if (res) {
                AlertOk(
                    'Atencion!',
                    'orange',
                    `<span>"${email}"</span> <br /> Correo Electrónico se encuentra registrado en la base de datos.`,
                    { ok: 'Ok' }
                );
            }
            return res;
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <Fragment>
            <div className="card">
                <div className="card-body">
                    <Formik
                        initialValues={dataForm}
                        enableReinitialize
                        validationSchema={Schema}
                        onSubmit={async (values, { resetForm }) => {
                            values.cardId.trim();
                            values.courses = selectionCourses(values.opt1, values.opt2, values.opt3);
                            let check = await checkEmail(values.email);
                            if (check === false) {
                                const msgCP = await post(endPointCreatePerson, values);
                                if (faildErrors.includes(msgCP.toString()) === false) {
                                    const msg = await post(endPointCoursesParticipant, values);
                                    if (msg) {
                                        action(true);
                                        resetForm({ values: '' });
                                        setTimeout(programs, 5000);
                                    }
                                } else {
                                    AlertOk('Atencion!', 'purple', 'En estos momentos, el servidor esta tardando en responder. Intenta más tarde!', { ok: 'Ok' }, programs);
                                }
                            }
                        }}
                    >
                        {({ handleSubmit, resetForm, isSubmitting, errors, touched, values }) => {
                            return (
                                <Form noValidate onSubmit={handleSubmit} className="row g-3 needs-validation candidate">
                                    <div className="col-md-4">
                                        <label htmlFor="validationCustom02" className="form-label">Cédula</label>
                                        <Field
                                            className="form-control"
                                            name="cardId"
                                            component={Cedula}
                                            onBlur={(e) => findPerson(e.target.value, resetForm)}
                                            placeholder="V-12345678"
                                        />
                                        {errors.cardId && touched.cardId ? (
                                            <div className="input-feedback">{errors.cardId}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="validationCustom01" className="form-label">Nombres</label>
                                        <Field
                                            className="form-control text-capitalize"
                                            type="text"
                                            placeholder="Pedro"
                                            name="names"
                                        />
                                        {errors.names && touched.names ? (
                                            <div className="input-feedback">{errors.names}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="validationCustom02" className="form-label">Apellidos</label>
                                        <Field
                                            className="form-control text-capitalize"
                                            type="text"
                                            placeholder="Pérez"
                                            name="surnames"
                                        />
                                        {errors.surnames && touched.surnames ? (
                                            <div className="input-feedback">{errors.surnames}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="validationCustom03" className="form-label">Correo</label>
                                        <Field
                                            className="form-control"
                                            type="email"
                                            placeholder="pperez@correo.com"
                                            name="email"
                                            component={Email}
                                            onBlur={e => checkEmail(e.target.value)}
                                        />
                                        {errors.email && touched.email ? (
                                            <div className="input-feedback">{errors.email}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="validationCustom05" className="form-label">Teléfono o Movil</label>
                                        <Field
                                            className="form-control"
                                            component={Telefono}
                                            name="phone"
                                            placeholder="0416 123 4556"
                                        />
                                        {errors.phone && touched.phone ? (
                                            <div className="input-feedback">{errors.phone}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="validationCustom04" className="form-label">Estado</label>
                                        <Field name="state">
                                            {({ field, props, form, meta }) => (
                                                <select
                                                    {...field}
                                                    className="form-select"
                                                    onChange={(e) => {
                                                        form.setFieldValue(field.name, e.target.value);
                                                        loadOffice(e.target.value);
                                                    }}
                                                >
                                                    <option value=''> - Seleccione - </option>
                                                    {states.map((state, i) => {
                                                        return <option key={i} value={state.id} >{state.value}</option>;
                                                    })}
                                                </select>
                                            )}
                                        </Field>
                                        {errors.state && touched.state ? (
                                            <div className="input-feedback">{errors.state}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="validationCustom04" className="form-label">Centro de Formación</label>
                                        <Field name="cfs">
                                            {({ field, props, form, meta }) => (
                                                <select
                                                    {...field}
                                                    className="form-select"
                                                    onChange={(e) => {
                                                        form.setFieldValue(field.name, e.target.value);
                                                        loadCourses(e.target.value, form);
                                                    }}
                                                >
                                                    {offices.length === 0 ?
                                                        <option value="">- Seleccione -</option> :
                                                        offices.map((office, i) => {
                                                            return <option key={i} value={office.id}>{office.value}</option>;
                                                        })}
                                                </select>
                                            )}
                                        </Field>
                                        {errors.cfs && touched.cfs ? (
                                            <div className="input-feedback">{errors.cfs}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="validationCustom04" className="form-label"> Opción Formativa</label>
                                        <Field name="opt1">
                                            {({ field, props, form, meta }) => (
                                                <select
                                                    {...field}
                                                    className="form-select"
                                                    onChange={(e) => {
                                                        form.setFieldValue(field.name, e.target.value);

                                                    }}
                                                >
                                                    {courses.length === 0 ?
                                                        <option value="">- Seleccione -</option> :
                                                        courses.map((course, i) => {
                                                            return <option key={i} value={course.id}>{course.value}</option>;
                                                        })}
                                                </select>
                                            )}
                                        </Field>
                                        {errors.opt1 && touched.opt1 ? (
                                            <div className="input-feedback">{errors.opt1}</div>
                                        ) : null}
                                    </div>
                                    {/* <div className="col-md-6">
                                        <label htmlFor="validationCustom04" className="form-label">2da Opción Formativa</label>
                                        <Field name="opt2">
                                            {({ field, props, form, meta }) => (
                                                <select
                                                    {...field}
                                                    className="form-select"
                                                    onChange={(e) => {
                                                        form.setFieldValue(field.name, e.target.value);

                                                    }}
                                                >
                                                    {courses.length === 0 ?
                                                        <option value={0}>- Seleccione -</option> :
                                                        courses.map((course, i) => {
                                                            return <option key={i} value={course.id}>{course.value}</option>;
                                                        })}
                                                </select>
                                            )}
                                        </Field>
                                        {errors.opt2 && touched.opt2 ? (
                                            <div className="input-feedback">{errors.opt2}</div>
                                        ) : null}
                                    </div> */}
                                    {/* <div className="col-md-6">
                                        <label htmlFor="validationCustom04" className="form-label">3ra Opción Formativa</label>
                                        <Field name="opt3">
                                            {({ field, props, form, meta }) => (
                                                <select
                                                    {...field}
                                                    className="form-select"
                                                    onChange={(e) => {
                                                        form.setFieldValue(field.name, e.target.value);
                                                    }}
                                                >
                                                    {courses.length === 0 ?
                                                        <option value={0}>- Seleccione -</option> :
                                                        courses.map((course, i) => {
                                                            return <option key={i} value={course.id}>{course.value}</option>;
                                                        })}
                                                </select>
                                            )}
                                        </Field>
                                        {errors.opt3 && touched.opt3 ? (
                                            <div className="input-feedback">{errors.opt3}</div>
                                        ) : null}
                                    </div> */}
                                    <div className="d-grid gap-2 col-12 mx-auto">
                                        <button className="btn btn-primary " type="submit" disabled={isSubmitting}>Únete ya!</button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </Fragment >
    );
};

export default FormsCandidate;
