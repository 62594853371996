/* eslint-disable no-unused-vars */
import React, { Fragment, useContext, useState, useEffect } from 'react';
import { ProSidebar, SidebarHeader, SidebarContent, SidebarFooter, Menu, MenuItem } from 'react-pro-sidebar';
import logo from '../../Assets/images/logo3.svg';
import miniLogo from '../../Assets/images/minilogo.svg';
import '../../Assets/menu-styles.css';
import LayoutContext from '../../context/layout/LayoutContext';
import LoginContext from '../../context/Login/LoginContext';

const styleSidebar = {
    backgroundColor: "white",
    position: "fixed",
    top: 0,
    transition: "width,left,right,.3s",
};

const Sidebar = () => {
    const [path, setPath] = useState(window.location.pathname);

    //Context
    const { miniSidebar } = useContext(LayoutContext);
    const { rol, fullname } = useContext(LoginContext);

    return (
        <Fragment>
            <aside className="left-sidebar">
                <ProSidebar collapsed={miniSidebar} breakPoint="md" style={styleSidebar}>
                    <div className="slimScrollDiv" style={{ overflow: 'visible', width: 'auto', height: '100%' }}>
                        <div className="scroll-sidebar" style={{ overflow: 'hidden', width: 'auto', height: '100%' }}>
                            <SidebarHeader>
                                <div className="user-profile text-center mt-2">
                                    <div className='profile-img'>
                                        <img
                                            src={miniSidebar ? miniLogo : logo}
                                            alt="user"
                                            style={miniSidebar ? { width: 50, height: 40, marginRight: 40 } : {}}
                                        />
                                    </div>
                                    <div className="profile-text"> {fullname} </div>
                                </div>
                            </SidebarHeader>
                            <SidebarContent>
                                <Menu iconShape="square">
                                    <MenuItem active={path === '/home'} icon={<i className="fas fa-columns"></i>}>
                                        <a href="/home">
                                            Dashboard
                                        </a>
                                    </MenuItem>
                                    {['10', '11', '17'].includes(rol) ?
                                        <MenuItem active={path === '/centros'} icon={<i className="fas fa-school"></i>}>
                                            <a href="/centros">Organizaciones</a>
                                        </MenuItem> : null
                                    }
                                    <MenuItem active={path === '/formacion'} icon={<i className="fas fa-book"></i>}>
                                        <a href="/formacion">Formación</a>
                                    </MenuItem>
                                    <MenuItem active={path === '/matriculados'} icon={<i className="fas fa-th-list"></i>}>
                                        <a href="/matriculados">Matriculados</a>
                                    </MenuItem>
                                    {['10', '11'].includes(rol) ?
                                        <MenuItem active={path === '/usuarios'} icon={<i className="fas fa-users"></i>}>
                                            <a href="/usuarios">Usuarios</a>
                                        </MenuItem> : null
                                    }
                                    {['10', '11'].includes(rol) ?
                                        <MenuItem active={path === '/certificado'} icon={<i className="fas fa-certificate"></i>}>
                                            <a href="/certificado">Certificado</a>
                                        </MenuItem>:null
                                    }
                                </Menu>
                            </SidebarContent>
                        </div>
                    </div>
                    <SidebarFooter className="text-center">
                        <p>Diseñado por Andrés Benitez</p>
                    </SidebarFooter>
                </ProSidebar>
            </aside>
        </Fragment>
    );
};

export default Sidebar;
