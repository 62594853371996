/* eslint-disable no-unused-vars */
import { Table } from 'antd';
import React, { Fragment, useState, useEffect } from 'react';
import { AlertOk } from '../../../../Components/Alert/Alert';
import { endPointAllAreas, endPointCreateMasiveCore, endPointStates } from '../../../../Services/EndPoints';
import { get, post } from '../../../../Services/HttpRequest';


const Masive = ({ action }) => {
    const [dataSource, setDataSource] = useState([]);
    const [headers, setHeaders] = useState(['id', 'id_estado', 'codigo', 'nombre', 'direccion', 'id_ambito']);
    const [scopes, setScopes] = useState([]);
    const [states, setStates] = useState([]);
    const [buttonSave, setButtonSave] = useState(false);
    const [files, setFiles] = useState('');

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: '5%',
            render: (text, record, index) => {
                return index + 1;
            }
        },
        {
            title: 'Id Estado',
            dataIndex: 'id_estado',
            key: 'id_estado',
            width: '10%',
        },
        {
            title: 'Código',
            dataIndex: 'codigo',
            key: 'codigo',
        },
        {
            title: 'Nombre',
            dataIndex: 'nombre',
            key: 'nombre',
        },
        {
            title: 'Dirección',
            dataIndex: 'direccion',
            key: 'direccion',
        },
        {
            title: 'Id Ambito',
            dataIndex: 'id_ambito',
            key: 'id_ambito',
            width: '10%',
        },
    ];

    const processCSV = (str) => {
        //Now what delimiter are we using?
        let delim = str.slice(0, str.indexOf('\n')).includes(',') ? ',' : ';';
        //Split the string into an array of rows
        const rows = str.slice(str.indexOf('\n') + 1).split('\n');
        //Remove row
        rows.splice(rows.indexOf('\n'), 1);

        try {
            const newArray = rows.map((row, index) => {
                const values = row.split(delim);
                let obj = values.length === 5 && values.reduce((obj, value, i) => {
                    obj[headers[i + 1]] = verifyData(index, headers[i + 1], value);
                    return obj;
                }, {});
                obj.key = index;
                return obj;
            });
            setButtonSave(true);
            setDataSource(newArray);
        } catch (error) {
            setFiles('');
            AlertOk('Error!',
                'red',
                `Ha ocurrido un error al procesar el archivo: Fila ${error.index + 1}, ${error.message}`,
                { ok: 'Ok' });
        }
    };

    const verifyData = (index, header, value) => {
        let BreakException = {};
        try {
            if (header === 'id_estado') {
                return states.find(i => i.estado === value).id;
            } else if (header === 'id_ambito') {
                return scopes.find(i => i.shortname === value).id;
            } else {
                return value;
            }
        } catch (error) {
            BreakException = {
                index: index + 1,
                message: `El valor <span className='error'>${value}</span> no es válido para el campo <span className='error'>${header}</span>`
            };
            throw BreakException;
        }
    };

    const handleReadFile = (data) => {
        const reader = new FileReader();
        reader.onload = function (e) {
            const text = e.target.result;
            processCSV(text);
        };
        reader.readAsText(data);
    };

    const handleSubmit = async () => {

        const response = await post(endPointCreateMasiveCore, dataSource);
        if (response.message !== undefined && response.response === undefined) {
            setButtonSave(false);
            AlertOk('Éxito!',
                'green',
                response.message,
                { ok: 'Ok' },
                () => { action(); });
        } else {
            if (response.response.status === 400) {
                AlertOk('Error!',
                    'red',
                    response.response.data.message,
                    { ok: 'Ok' });
            }
        }
    };


    const loadData = async () => {
        let toStates = await get(endPointStates);
        let toScopes = await get(endPointAllAreas);
        setStates(toStates);
        setScopes(toScopes);
    };


    useEffect(() => {
        loadData();
    }, []);

    return <Fragment>
        <div className="row mb-1">
            <div className="col-4 me-auto mb-1">
                <input
                    type="file"
                    id="files"
                    value={files}
                    className="form-control"
                    accept=".csv"
                    onChange={(e) => {
                        handleReadFile(e.target.files[0]);
                    }}
                    required
                />

            </div>
            <div className="col-3 ms-auto mb-1">
                <button className="btn btn-outline-inverse float-start" onClick={() => action()}>
                    Regresar <i className="fas fa-backward"></i></button>
                {buttonSave ? <button className="btn btn-outline-primary ms-3" onClick={() => { handleSubmit(); }} >
                    Guardar <i className="fas fa-save"></i>
                </button> : null}
            </div>
        </div>
        <Table dataSource={dataSource} columns={columns} />

    </Fragment>;
};

export default Masive;;