/* eslint-disable no-unused-vars */
import React, { Fragment, useContext } from 'react';
import { BrowserRouter as Router, Redirect, Route, Link } from 'react-router-dom';
import routes from '../../Services/PagesRoute';
import LayoutContext from '../../context/layout/LayoutContext';
import { isAuthenticated } from '../../Services/Authentications';
import LoginContext from '../../context/Login/LoginContext';

const ProtectedRoute = ({ component: Component, auth, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated() ? (<Component {...props} />) : cleanStorages()
            }
        />
    );
};

const PublicRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={(props) => <Component {...props} />} />
    );
};

const cleanStorages = () => {
    sessionStorage.clear();
    localStorage.clear();
    return <Redirect to="/" />;
};

const Content = () => {
    const { content } = useContext(LayoutContext);
    const breadcrumb = routes.filter((e) => e.path === '/' + window.location.pathname.split('/')[1])[0];

    return (
        <Fragment>
            <div id="content" className={content ? 'container-fluid' : ''}>
                {content ? <div className="col-md-6 col-8 align-self-center">
                    <h3 className="text-themecolor m-b-0 m-t-0 text-capitalize">{breadcrumb.name}</h3>
                </div> : null}
                <Router>
                    {routes.map((route, index) =>
                        route.private ? (
                            <ProtectedRoute
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                component={route.component}
                            />
                        ) : (
                            <PublicRoute
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                component={route.component}
                            />
                        )
                    )}
                </Router>
            </div>
        </Fragment>
    );
};

export default Content;
