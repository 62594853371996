import { WITH_LAYOUT } from '../actions';

// eslint-disable-next-line import/no-anonymous-default-export
export default (states, action) => {
    const { payload, type } = action;
    if (type === WITH_LAYOUT) {
        return {
            ...states,
            navbar: payload.navbar,
            sidebar: payload.sidebar,
            miniSidebar: payload.miniSidebar,
            content: payload.content,
            footer: payload.footer,
        };
    } else {
        return states;
    }
};