import { GET_LOGIN } from '../actions';

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
    const { payload, type } = action;
    switch (type) {
        case GET_LOGIN:
            return {
                ...state,
                token: payload.token,
                user: payload.user,
                rol: payload.rol,
                cfs: payload.cfs,
                state: payload.state,
                fullname: payload.fullname,
            };
        default:
            return state;
    }
};