/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import LayoutContext from '../../context/layout/LayoutContext';
import { logout } from '../../Services/Authentications';

const Navbar = () => {
    const { dispatchLayout, miniSidebar, navbar, sidebar, content, footer, } = useContext(LayoutContext);

    const [showSideBar, setShowSideBar] = useState(miniSidebar);

    const changeSideBar = () => {
        setShowSideBar(!showSideBar);

        const miniSB = {
            navbar,
            sidebar,
            content,
            footer,
            miniSidebar: !showSideBar
        };

        sessionStorage.setItem('layout', JSON.stringify(miniSB));

        dispatchLayout({
            type: 'WITH_LAYOUT',
            payload: miniSB
        });
    };

    return (
        <Fragment>
            <header className="topbar" style={{ position: 'fixed', top: 0, width: '100%' }}>
                <nav className="navbar top-navbar navbar-toggleable-sm navbar-light">
                    <div className="navbar-header"></div>
                    <div className="navbar-collapse">
                        <ul className="navbar-nav mr-auto mt-md-0 ">
                            {showSideBar ? <li className="nav-item">
                                <Link className="nav-link nav-toggler hidden-md-up text-muted waves-effect waves-dark" to="#" onClick={() => changeSideBar()}>
                                    <i className="ti-menu" />
                                </Link>
                            </li> : <li className="nav-item">
                                <Link to="#" className="nav-link sidebartoggler hidden-sm-down text-muted waves-effect waves-dark" onClick={() => changeSideBar()}>
                                    <i className="icon-arrow-left-circle" />
                                </Link>
                            </li>}
                        </ul>
                    </div>
                    <div className="nav-item nav-link close">
                        <a onClick={() => logout()}><i className="fas fa-sign-out-alt"></i> </a>
                    </div>
                </nav>
            </header>
            <div style={{ position: 'relative', width: 1585, height: 60, display: 'block', verticalAlign: 'baseline', float: 'none' }}></div>
        </Fragment>
    );
};

export default Navbar;
