/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import LoginContext from '../../context/Login/LoginContext';
import LayoutContext from '../../context/layout/LayoutContext';
import logo from '../../Assets/images/logo3.svg';
import background_local from '../../Assets/images/bg/cintillo.svg';
import { isAuthenticated, login } from '../../Services/Authentications';
import { AlertOk } from '../../Components/Alert/Alert';

import '../../Assets/css/Login.css';
import { post } from '../../Services/HttpRequest';
import { endPointUserChangePass, endPointVerifyTempPass } from '../../Services/EndPoints';

const listBg = [
    'https://getwallpapers.com/wallpaper/full/2/c/a/165944.jpg',
    'https://getwallpapers.com/wallpaper/full/0/e/6/165796.jpg',
    'https://getwallpapers.com/wallpaper/full/c/d/6/165859.jpg',
    background_local
];

const Login = () => {

    const { dispatch } = useContext(LoginContext);
    const { dispatchLayout } = useContext(LayoutContext);
    const [background, setBackground] = useState('');
    const [isLogged, setIsLogged] = useState(isAuthenticated());
    const [changePassword, setChangePassword] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        let validation = e.target.username.value !== '' && e.target.password.value !== '' ? true : false;

        let validationNewPass = changePassword ? e.target.newPassword.value === e.target.repeatPassword.value ? true : false : true;

        if (!validationNewPass) {
            AlertOk('Atencion!', 'red', 'La Contraseña nueva y su repetición no coinciden.', { ok: 'Ok' });
            validation = false;
        }

        if (validation) {

            let pass = e.target.password.value;

            try {
                if (changePassword) {

                    pass = e.target.newPassword.value;

                    const res = await post(endPointUserChangePass, { username: e.target.username.value, newPassword: pass });

                    if (res.msg === 'Password Changed') {
                        AlertOk('Información!', 'blue', 'La Contraseña ha sido cambiada de forma satisfactoria.', { ok: 'Ok' });
                    }
                }
            } catch (error) {
                AlertOk('Alerta!', 'red', 'Algo ha salido mal. Intente más tarde.', { ok: 'Ok' });
            }

            let res = await login(e.target.username.value, pass);

            if (res.toString().split(' ')[6] === '402') {
                AlertOk('Atencion!', 'red', 'Usuario y contraseña no coinciden.', { ok: 'Ok' });
                e.target.username.value = e.target.password.value = '';
            }

            if (res.toString().split(' ')[6] === '401') {
                AlertOk(
                    'Atencion!',
                    'orange',
                    'Usuario se encuentra inactivo. Debe comunicarse con el Administrador',
                    { ok: 'Ok' }
                );
                e.target.username.value = e.target.password.value = '';
            }

            if (res.toString() === 'Error: Network Error' || res.toString().split(' ')[6] === '500') {
                AlertOk('Alerta!', 'red', 'Ha ocurrido un error de comunicación con el Servidor!', { ok: 'Ok' });
                e.target.username.value = e.target.password.value = '';
            }

            if (res.token !== undefined) {

                const layout = {
                    navbar: true,
                    sidebar: true,
                    miniSidebar: false,
                    content: true,
                    footer: true,
                };

                dispatchLayout({ type: 'WHIT_LAYOUT', payload: layout });

                const user = {
                    token: res.token,
                    user: e.target.username.value,
                    rol: res.rol,
                    cfs: res.cfs,
                    state: res.state,
                    fullname: res.fullname
                };

                dispatch({ type: 'GET_LOGIN', payload: user });

                sessionStorage.setItem('layout', JSON.stringify(layout));
                window.location = '/home';
            }
        }
    };

    const handlePasswordUser = async (username) => {
        const res = await post(endPointVerifyTempPass, { username });
        if (res) {
            AlertOk(
                'Atencion!',
                'orange',
                'El usuario debe cambiar la contraseña',
                { ok: 'Ok' },
                () => { setChangePassword(true); }
            );
        } else {
            setChangePassword(false);
        }
    };

    const forgetPassword = () => {
        AlertOk(
            'Atencion!',
            'orange',
            '<span>Por favor!</span><br /> Debe comunicarse con el Jefe de Formación o con la Sede Principal',
            { ok: 'Ok' }
        );
    };

    useEffect(() => {
        if (isAuthenticated()) {
            window.location = '/home';
        }
        setBackground(listBg[Math.floor(Math.random() * listBg.length)]);
    }, []);

    return (
        <Fragment>
            {isLogged ? <Redirect to="/home" /> : null}
            <div className="login container">
                <div className="login-cover">
                    <div
                        className="login-cover-image"
                        style={{
                            backgroundImage: 'url(' + background + ')',
                        }}
                    ></div>
                    <div className="login-cover-bg"></div>
                </div>
                <div className="d-flex justify-content-center">
                    <div className="card especial" style={changePassword ? { height: '500px' } : null}>
                        <div className="card-header">
                            <h3>Inicio de Sesión</h3>
                            <div className="d-flex justify-content-end social_icon">
                                <img src={logo} style={{ width: 200 }} alt='' />
                            </div>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <div className="input-group form-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <i className="fas fa-user" />
                                        </span>
                                    </div>
                                    <input
                                        name="username"
                                        type="text"
                                        className="form-control"
                                        placeholder="Usuario"
                                        onBlur={(e) => handlePasswordUser(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="input-group form-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <i className="fas fa-key" />
                                        </span>
                                    </div>
                                    <input
                                        name="password"
                                        type="password"
                                        className="form-control"
                                        placeholder={changePassword ? 'Contraseña Asignada' : 'Contraseña'}
                                        required
                                    />
                                </div>
                                {changePassword ? <>
                                    <div className="input-group form-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fas fa-key" />
                                            </span>
                                        </div>
                                        <input
                                            name="newPassword"
                                            type="password"
                                            className="form-control"
                                            placeholder="Nueva Contraseña"
                                            required
                                        />
                                    </div>
                                    <div className="input-group form-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fas fa-key" />
                                            </span>
                                        </div>
                                        <input
                                            name="repeatPassword"
                                            type="password"
                                            className="form-control"
                                            placeholder="Repetir Contraseña"
                                            required
                                        />
                                    </div>
                                </> : null}
                                <div className="form-group mt-5 login_btn">
                                    <button
                                        type="submit"
                                        className="btn btn-outline-secondary"
                                    >
                                        {changePassword ? 'Cambiar Contraseña' : 'Iniciar'}
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="card-footer">
                            <div className="d-flex justify-content-center">
                                <Link to="#" onClick={() => forgetPassword()}>¿Olvido su Contraseña?</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Login;
