/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect, useContext } from 'react';
import LoginContext from '../../context/Login/LoginContext';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import { get, post } from '../../Services/HttpRequest';
import { endPointCheckEmailPerson, endPointCreatePerson, endPointCreateUser, endPointGetPerson, endPointGetUser, endPointOfficesStates, endPointStates, endPointVerifyUsername } from '../../Services/EndPoints';
import { AlertOk } from '../Alert/Alert';
import { data } from 'jquery';

const Cedula = ({ field, form, action, ...props }) => {
    const formatChars = {
        'a': '[VEPvep]',
        '9': '[0-9]',
        '*': '[A-Za-z0-9]'
    };

    return <InputMask
        {...props}
        mask="a-99999999999"
        maskChar=' '
        formatChars={formatChars}
        style={{ textTransform: 'uppercase' }}
        onChange={(e) => {
            let value = e.target.value.trim();
            if (value !== '-' && value !== '') {
                form.setFieldValue(field.name, value);
            } else {
                form.setFieldError(field.name, 'Requerida');
            }
        }}
    />;
};

const FormAddUser = ({ action = () => { } }) => {
    const { rol, state } = useContext(LoginContext);
    const [dataForm, setDataForm] = useState({
        cardId: '',
        names: '',
        surnames: '',
        email: '',
        phone: '',
        username: '',
        password: '',
        rol: ['17'].includes(rol) ? '16' : '',
        state: ['17'].includes(rol) ? state : '',
        cfs: ''
    });
    const [states, setStates] = useState([]);
    const [isRolAdmin, setIsRolAdmin] = useState(rol === '17' ? false : true);
    const [offices, setOffices] = useState([]);
    const [isOfficeAdmin, setIsOfficeAdmin] = useState(rol === '17' ? false : true);

    const Schema = Yup.object().shape({
        cardId: Yup.string().required('Requerida').trim().matches(new RegExp(`^[V|E|P]-[0-9]{5,11}$`, "gi"), 'Ejem: [V|E|P]-12345678'),
        names: Yup.string().required('Requerido'),
        surnames: Yup.string().required('Requerido'),
        email: Yup.string().required('Requerido'),
        phone: Yup.string().required('Teléfono o movil requerido').matches(new RegExp("^[0-9]{4} [0-9]{3} [0-9]{4}$", "g"), 'Número de Telef. incorrecto'),
        username: Yup.string().required('Requerido'),
        password: Yup.string().required('Requerido'),
        rol: !['17'].includes(rol) ? Yup.string().required('Requerido') : Yup.string(),
        state: !['10', '11', '17'].includes(rol) ? Yup.string().required('Requerido') : Yup.string(),
        cfs: !['10', '11'].includes(rol) ? Yup.string().required('Requerido') : Yup.string()
    });

    const findPerson = async (value) => {
        //TODO: Para evaluar las cedulas y las misma no tengan errores para registrarse.
        let res = dataForm;
        if (value.target.value !== '') {
            let nac = value.target.value.split('-')[0];
            let id_person = value.target.value.split('-')[1].trim();
            const regEx = ['V', 'v', 'E', 'e'].includes(nac) ?
                new RegExp(`^[V]-[0-9]{5,8}$`, "gi") :
                new RegExp(`^[P|E]-[0-9]{5,11}$`, "gi");
            if (regEx.test(value.target.value.trim())) {
                const resPerson = await post(endPointGetPerson, { id_person });
                const resUser = await post(endPointGetUser, { id_person });
                if (!resUser) {
                    res = {
                        cardId: resPerson ? `${resPerson.nac}-${resPerson.cedula}`.trim() : value.target.value.trim(),
                        names: resPerson ? resPerson.nombres : '',
                        surnames: resPerson ? resPerson.apellidos : '',
                        email: resPerson ? resPerson.correo : '',
                        phone: resPerson ? resPerson.telefono : '',
                        username: '',
                        password: '',
                        rol: ['17'].includes(rol) ? '16' : '',
                        state: ['17'].includes(rol) ? state : '',
                        cfs: ''
                    };
                    setDataForm(res);
                } else {
                    AlertOk(
                        'Información!',
                        'orange',
                        `El documento ${value.target.value.trim()} Posee un usuario asociado!`,
                        { ok: 'Ok' },
                        () => { action(true); }
                    );

                }
            } else {
                AlertOk(
                    'Advertencia!',
                    'orange',
                    `El documento de identificación ${value.target.value.trim()} no cumple con lo requerido!`,
                    { ok: 'Ok' }
                );
            }
        }
    };

    const checkEmail = async (email, form) => {
        let cardId = dataForm.cardId;
        if (email !== '' && cardId !== '') {
            const res = await get(endPointCheckEmailPerson, { email });
            if (res) {
                AlertOk(
                    'Atencion!',
                    'orange',
                    `<span>"${email}"</span> <br /> Correo Electrónico se encuentra registrado en la base de datos.`,
                    { ok: 'Ok' }
                );
                form.setTouched({ email: true }, false);
                form.setFieldError('email', 'Correo registrado', false);
            }
            return res;
        }
    };

    const checkUsername = async (username, form) => {
        const res = await post(endPointVerifyUsername, { username });
        if (res) {
            AlertOk(
                'Atencion!',
                'orange',
                `<span>"${username}"</span> <br /> El Nombre de Usuario esta siendo usado por otro usuario.`,
                { ok: 'Ok' }
            );
            form.setTouched({ username: true }, false);
            form.setFieldError('username', 'Usuario ya registrado');
            form.setFieldValue('username', '');
        }
        console.log(res, form);
    };

    const handleSelectRol = (rol, form) => {
        if (rol === '11' || rol === '') {
            setIsRolAdmin(true);
            setIsOfficeAdmin(true);
            form.setFieldValue('state', '');
            form.setFieldValue('cfs', '');
        }
        if (rol === '17') {
            setIsRolAdmin(false);
            setIsOfficeAdmin(true);
            form.setFieldError('state', 'Requerido');
        }
        if (rol === '16') {
            setIsRolAdmin(false);
            setIsOfficeAdmin(false);
        }
    };

    const loadStates = async () => {
        let estados = [];
        const resStates = await get(endPointStates);
        if (resStates.toString() !== 'Error: Network Error') {

            resStates.forEach(state => {
                if (state.id !== '25') {
                    estados.push({
                        id: state.id,
                        value: state.estado
                    });
                }
            });
            estados.sort((a, b) => {
                let nameA = a.value.toUpperCase();
                let nameB = b.value.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
            setStates([...estados]);
        } else {
            AlertOk(
                'Información!',
                'orange',
                'En estos momentos, el servidor esta tardando en responder. Intenta más tarde!',
                { ok: 'Ok' },
                () => { }
            );
        }
    };

    const handleSelectState = async (id_state) => {
        if (id_state !== '') {
            let sedes = [];
            const resOffices = await get(endPointOfficesStates, { id_state });
            resOffices.forEach(office => {
                sedes.push({
                    id: office.id,
                    value: office.nombre
                });
            });
            setOffices(sedes);

        }
    };

    useEffect(() => {
        loadStates();
        if (['17'].includes(rol)) {
            handleSelectState(state);
        }
    }, []);

    return (
        <Fragment>
            <div className="card card-block p-5 ">
                <h3 className="box-title ">Datos del usuario</h3>
                <Formik
                    initialValues={dataForm}
                    validationSchema={Schema}
                    onSubmit={async (values, { resetForm }) => {
                        const respCP = await post(endPointCreatePerson, values);
                        const respCU = await post(endPointCreateUser, values);

                        resetForm({ values: '' });
                        AlertOk(
                            'Informacion!',
                            'purple',
                            `Muy bien! <br /> <spam className='mt-2'>El Usuario ha sido creado satisfactoriamente.</spam> `,
                            { ok: 'Ok' },
                            () => { action(2); }
                        );
                    }}
                    enableReinitialize
                >
                    {({ handleSubmit, resetForm, isSubmitting, errors, touched }) => {
                        return (
                            <Form className="mt-1" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-3">
                                        <label htmlFor="cardId">Cédula</label>
                                        <Field component={Cedula} onBlur={findPerson} className="form-control" name="cardId" placeholder="V-12345678" />
                                        {errors.cardId && touched.cardId ? (
                                            <div className="input-feedback">{errors.cardId}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-6">
                                        <label htmlFor="names">Nombres</label>
                                        <Field className="form-control" name="names" placeholder="Jane" />
                                        {errors.names && touched.names ? (
                                            <div className="input-feedback">{errors.names}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="surnames">Apellidos</label>
                                        <Field className="form-control" name="surnames" placeholder="Doe" />
                                        {errors.surnames && touched.surnames ? (
                                            <div className="input-feedback">{errors.surnames}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-6">
                                        <label htmlFor="lastName">Teléfono</label>
                                        <Field name="phone" >
                                            {({ field, props, form, meta }) => {
                                                return <InputMask
                                                    {...field}
                                                    className="form-control"
                                                    placeholder="0412 234 56789"
                                                    mask="9999 999 9999"
                                                    maskChar=' '
                                                />;
                                            }}
                                        </Field>
                                        {errors.phone && touched.phone ? (
                                            <div className="input-feedback">{errors.phone}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="email">Correo</label>
                                        <Field name="email">
                                            {({ field, props, form, meta }) => (
                                                <input
                                                    {...field}
                                                    {...props}
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="jane@acme.com"
                                                    onBlur={e => checkEmail(e.target.value, form)}
                                                    disabled={dataForm.cardId === ''}
                                                />
                                            )}
                                        </Field>
                                        {errors.email && touched.email ? (
                                            <div className="input-feedback">{errors.email}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-6">
                                        <label htmlFor="username">Usuario</label>
                                        <Field name="username">
                                            {({ field, props, form, meta }) => (
                                                <input
                                                    {...field}
                                                    {...props}
                                                    className="form-control"
                                                    placeholder="abenitez"
                                                    type="text"
                                                    onBlur={e => checkUsername(e.target.value, form)}
                                                />
                                            )}
                                        </Field>
                                        {errors.username && touched.username ? (
                                            <div className="input-feedback">{errors.username}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="password">Contraseña</label>
                                        <Field type="password" className="form-control" name="password" placeholder="Contraseña" />
                                        {errors.password && touched.password ? (
                                            <div className="input-feedback">{errors.password}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    {['10', '11'].includes(rol) ? <div className="col-md-4">
                                        <label htmlFor="Rol">Rol</label>
                                        <Field name="rol">
                                            {({ field, props, form, meta }) => (
                                                <select
                                                    className="form-select"
                                                    {...field}
                                                    onClick={(e) => { handleSelectRol(e.target.value, form); }}
                                                >
                                                    <option value=""> Selecione </option>
                                                    <option value="11"> Sede </option>
                                                    <option value="17"> Jefe de Formacion (Regional)</option>
                                                    <option value="16"> Jefe CFS</option>
                                                    <option value="20"> INCES Militar</option>
                                                </select>
                                            )}
                                        </Field>
                                        {errors.rol && touched.rol ? (
                                            <div className="input-feedback">{errors.rol}</div>
                                        ) : null}
                                    </div> : null}
                                    <div className="col-md-4">
                                        <label htmlFor="Estado">Estado</label>
                                        <Field name="state">
                                            {({ field, props, form, meta }) => (
                                                <select
                                                    className="form-select"
                                                    {...field}
                                                    onClick={(e) => handleSelectState(e.target.value)}
                                                    disabled={['17'].includes(rol) ? true : isRolAdmin ? true : false}
                                                >
                                                    <option value=""> Selecione </option>
                                                    {states.map((state, index) => {
                                                        return <option key={index} value={state.id} >{state.value}</option>;
                                                    })}
                                                </select>
                                            )}
                                        </Field>
                                        {errors.state && touched.state ? (
                                            <div className="input-feedback">{errors.state}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="password">CFS</label>
                                        <Field name="cfs" >
                                            {({ field, props, form, meta }) => (
                                                <select
                                                    className="form-select"
                                                    {...field}
                                                    disabled={isRolAdmin ? true : isOfficeAdmin ? true : false}
                                                >
                                                    <option value=""> Selecione </option>
                                                    {offices.map((office, index) => {
                                                        return <option key={index} value={office.id} >{office.value}</option>;
                                                    })}
                                                </select>
                                            )}
                                        </Field>
                                        {errors.cfs && touched.cfs ? (
                                            <div className="input-feedback">{errors.cfs}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="row mt-5 mb-2 ">
                                    <div className="col-md-4 ml-5 ms-auto">
                                        <button
                                            className="btn btn-inverse m-r-10"
                                            onClick={() => resetForm()}
                                        > Cancelar <i className="fas fa-undo"></i>
                                        </button>
                                        <button className="btn btn-success" type="submit" disabled={isSubmitting}>Enviar <i className="far fa-paper-plane"></i></button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </Fragment >
    );
};

export default FormAddUser;

