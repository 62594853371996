import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import LayoutStates from './context/layout/LayoutStates';
import LoginStates from './context/Login/LoginStates';

import 'jquery-confirm/dist/jquery-confirm.min.js';
import 'jquery-confirm/dist/jquery-confirm.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './Assets/css/rsuite-default.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'antd/dist/antd.css';

import './Assets/css/style-unminify.css';
import './Assets/css/blue.css';
import './Assets/css/App.css';

import { IntlProvider } from 'rsuite';
import esES from 'rsuite/lib/IntlProvider/locales/es_ES';

ReactDOM.render(

    <LoginStates>
        <LayoutStates>
            <BrowserRouter>
                <IntlProvider locale={esES}>
                    <App />
                </IntlProvider>
            </BrowserRouter>
        </LayoutStates>
    </LoginStates>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
