/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { Fragment, useContext, useEffect, useState } from 'react';
import LayoutContext from '../../context/layout/LayoutContext';
import moment from 'moment';

import batalla from '../../Assets/images/cintillo/200-Carabobo.png';
import logo from '../../Assets/images/logo.svg';
import dataManagement from '../../Assets/images/svg/001-data-management.svg';
import education from '../../Assets/images/svg/003-education.svg';
import project from '../../Assets/images/svg/005-project.svg';
import repair from '../../Assets/images/svg/007-repair.svg';
import share from '../../Assets/images/svg/010-share.svg';
import certificate from '../../Assets/images/svg/011-certificate.svg';
import saberes from '../../Assets/images/programas/acreditacion-de-saberes.png';
import bachillerato from '../../Assets/images/programas/bachillerato-productivo.png';
import trabajo from '../../Assets/images/programas/formacion-en-entidades-de-trabajo.png';
import productivo from '../../Assets/images/programas/formacion-productiva.png';
import militar from '../../Assets/images/programas/inces-militar.png';
import aprendices from '../../Assets/images/programas/programa-nacional-de-aprendices.png';
import FormsCandidate from '../../Components/Forms/FormsCandidate';
import ModalFormation from '../../Components/Modal/ModalFormation';

import { infoAprendices, infoBachillerato, infoProductivo, infoMilitar, infoSaberes, infoTrabajo } from './InfoFormPrograms';
import ModalCertificateRequest from '../../Components/Modal/ModalCertificateRequest';

const Landingpage = () => {

    const { dispatchLayout, miniSidebar, } = useContext(LayoutContext);
    const [isCongrats, setIsCongrats] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [dataModal, setDataModal] = useState({});
    const [openModalCertificate, setOpenModalCertificate] = useState(false);
    const [dataModalCertificate, setDataModalCertificate] = useState({});
    const [register, setRegister] = useState(false);

    const changedContext = () => {
        let context = window.location.pathname !== '/' ? true : false;
        const layout = {
            navbar: context,
            sidebar: context,
            miniSidebar,
            content: context,
            footer: context
        };
        dispatchLayout({
            type: 'WITH_LAYOUT',
            payload: layout
        });
    };

    const changedRegister = () => {
        setRegister(!register);
        setOpenModal(false);
    };

    const showModal = (data) => {
        setDataModal(data);
        setOpenModal(!openModal);
    };

    const showModalCerticadeRequest = () => {
        setDataModalCertificate('data');
        setOpenModalCertificate(!openModalCertificate);
    };

    useEffect(() => {
        changedContext();
    }, []);

    return (
        <Fragment>
            <div id="fondo"></div>
            <div className="container p-4" style={{ position: 'relative' }}>
                <div id="infoince" className="row p-2">
                    <div className="col-md-2">
                        <img src={logo} width="100%" alt="" className="text-center" />
                    </div>
                </div>
                {register ?
                    <div className="row" style={{ position: 'relative' }}>
                        <div id="infoince" className="col-md-5 ">
                            <p className="align-middle text-justify">En el Instituto Nacional de Capacitación y Educación Socialista dimos el gran paso a la tecnología 4.0 y creamos un espacio de formación digital para el beneficio de todo el pueblo venezolano. Una plataforma innovadora que funcione como punto de encuentro para el desarrollo académico y personal de los participantes. Bienvenidos al campo virtual INCES</p>
                        </div>
                        <div className="col-md-6 align-self-center ms-auto candidate">
                            {isCongrats ?
                                <>
                                    <div className="card" style={{ width: '39rem', height: '30rem' }}>
                                        <div className="card-body text-center mt-5">
                                            <h1 className="mt-5">¡Gracias!</h1>

                                            <h3 className="mt-5">Tu inscripción ha sido registrada.</h3>
                                            <h3 className="mt-3">Te contactaremos poco antes de iniciar la formación</h3>
                                        </div>
                                    </div>
                                </>
                                : <FormsCandidate action={setIsCongrats} programs={changedRegister} />}

                        </div>
                    </div> :
                    <div className="programs" style={{ position: 'relative' }}>
                        <div className="row text-center">
                            <div className="col-md-4">
                                <img src={saberes} alt="" width="55%" onClick={() => showModal(infoSaberes)} style={{ cursor: 'pointer' }} />
                                <img src={bachillerato} alt="" width="55%" onClick={() => showModal(infoBachillerato)} style={{ cursor: 'pointer' }} />
                            </div>
                            <div className="col-md-4">
                                <img src={trabajo} alt="" width="55%" onClick={() => showModal(infoTrabajo)} style={{ cursor: 'pointer' }} />
                                <img src={productivo} alt="" width="55%" onClick={() => showModal(infoProductivo)} style={{ cursor: 'pointer' }} />
                            </div>
                            <div className="col-md-4">
                                <img src={militar} alt="" width="55%" onClick={() => showModal(infoMilitar)} style={{ cursor: 'pointer' }} />
                                <img src={aprendices} alt="" width="55%" onClick={() => showModal(infoAprendices)} style={{ cursor: 'pointer' }} />
                            </div>
                        </div>
                        <ModalFormation show={openModal} data={dataModal} onHide={() => setOpenModal(!openModal)} action={() => changedRegister()} />
                        {false ? <div className="row mb-3 pb-5 justify-content-md-center" >
                            <div className="col-md-auto">
                                <input className="form-control" width="200px" type="search" name="" id="" placeholder="Buscador" />
                            </div>
                        </div> : null}
                    </div>}
                <div id="icon-info" className="row mt-3">
                    <div className="col text-center ">
                        <p>
                            <img src={dataManagement} alt="" width="35%" />
                        </p>
                        <h6>Formación Técnica</h6>
                    </div>
                    <div className="col text-center">
                        <p>
                            <img src={education} alt="" width="35%" />
                        </p>
                        <h6>Conocimiento Digital</h6>
                    </div>
                    <div className="col text-center">
                        <p>
                            <img src={project} alt="" width="35%" />
                        </p>
                        <h6>Desarrollo personal y profesional</h6>
                    </div>
                    <div className="col text-center">
                        <p>
                            <img src={repair} alt="" width="35%" />
                        </p>
                        <h6>Herramientas productivas</h6>
                    </div>
                    <div className="col text-center">
                        <p>
                            <img src={share} alt="" width="35%" />
                        </p>
                        <h6>Uso de las Tic’s </h6>
                    </div>
                    <div className="col text-center">
                        <p>
                            <img style={{ cursor: 'pointer' }} src={certificate} alt="" width="35%" onClick={() => showModalCerticadeRequest()} />
                        </p>
                        <h6>Descarga de Certificado</h6></div>
                </div>
                <ModalCertificateRequest show={openModalCertificate} data={dataModal} onHide={() => setOpenModalCertificate(!openModalCertificate)} action={() => setOpenModalCertificate(!openModalCertificate)} />

                <div id="info-2" className="row mb-3">
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Conoce nuestra metodología educativa</h5>
                                <p className="card-text text-justify mt-3">En el INCES trazamos las líneas rectoras, que vinculan a la educación técnica con el ámbito laboral, para ofrecerte mayores oportunidades de estudio, trabajo y emprendimiento.</p>
                                <p className="card-text text-justify mt-3">¿Cómo lo hacemos? <br /> Organizamos nuestras formaciones en Unidades Curriculares    reconocidas y homologables en las Universidades Experimentales del país. </p>
                                <p className="card-text text-justify mt-3">Para incorporarte al Inces, te ofrecemos diversas modalidades formativas: presencial, semipresencial, a distancia (mediadas por las Tecnologías de la Información y la Comunicación) o solicitando el reconocimiento y acreditación de los saberes que has adquirido en el trabajo o a lo largo de la vida.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card" >
                            <div className="card-body">
                                <h5 className="card-title">Perfil Productivo</h5>
                                <p className="card-text text-justify mt-3">En el Inces, para configurar un perfil productivo/laboral combinamos un conjunto de Unidades Curriculares afines. De esta manera, podrás adquiririr conocimientos y experiencias, así como desarrollar habilidades y destrezas que te permitirán resolver problemas de tu entorno laboral.
                                </p>
                                <p className="card-text text-justify mt-3">
                                    Desarrollar un perfil productivo/laboral te permitirá: <br />
                                    - Actualizarte o especializarte. (Área laboral) <br />
                                    - Desarrollar competencias. (Actividad productiva / laboral)<br />
                                    - Formarte en una ocupación productiva. (Empresa o centro de trabajo).


                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card" >
                            <div className="card-body">
                                <h5 className="card-title">Unidades curriculares</h5>
                                <p className="card-text text-justify mt-3">Como parte de nuestro proceso de transformación decidimos reorganizar las formaciones que te ofrecemos en Unidades Curriculares. Este cambio te garantiza:</p>
                                <p className="card-text text-justify mt-3">La continuidad de estudios en universidades experimentales, al homologar las unidades curriculares que apruebes con las materias de tu plan de estudios.</p>
                                <p className="card-text text-justify mt-3">Desarrollar competencias específicas a ejecutar tanto en el campo laboral como en tu vida personal.</p>
                                <p className="card-text text-justify mt-3">Ofrece herramientas que te permitirán resolver problemas en tu entorno o contexto laboral.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div id="cintillo">
                <div className="row">
                    <div className="col-10"></div>
                    <div className="col-2 mx-auto showBatalla"><img src={batalla} alt="" width="60%" /></div>
                </div>
            </div>
            <div className="pre-footer">
                <div className="container pt-4">
                    <h4>Preguntas Frecuentes</h4>
                    <div className="row">
                        <div className="col-md-3 p-2">
                            <span className="fst-italic">¿Puedo hacer dos cursos a la vez?</span>
                            <p className="tabulacion text-justify">La plataforma te da la opción de inscribir hasta tres formaciones mensuales.</p>
                        </div>
                        <div className="col-md-3 p-2">
                            <span className="fst-italic"> ¿Puedo participar desde cualquier parte del país?</span>

                            <p className="tabulacion text-justify fst-italic">Sí; sin embargo, en algunas especialidades requieren práctica presencial. En esos casos, la plataforma te da la información que requieres.</p>
                        </div>
                        <div className="col-md-3 p-2">
                            <span className="fst-italic">¿Me dan certificación?</span>

                            <p className="tabulacion text-justify fst-italic">Sí. Una vez aprobadas todas las unidades curriculares de tu formación, podrás descargar o imprimir tu certificado INCES.</p>
                        </div>
                        <div className="col-md-3 p-2">
                            <span className="fst-italic">¿A partir de qué edad puedo participar?</span>
                            <p className="tabulacion text-justify fst-italic">
                                A partir de los 14 años en adelante.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <footer >
                <hr />
                <p className="text-center pt-3">Hecho para el Sitio Web INCES Copyright © {moment().format('YYYY')} Rif: G-20009922-4</p>
            </footer>
        </Fragment>
    );
};

export default Landingpage;
